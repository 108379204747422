import React, { useContext } from 'react';
import { Image, Row, Col, Container } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Logo1 from '../asset/new_asset/icon_web/appstore.png';
import Logo2 from '../asset/new_asset/icon_web/playstore.png';
import { AppContext, AppContextProvider } from '../utils/AppContext';
import { useTranslation } from 'react-i18next';
import ReactGA from "react-ga4";

import Sosmed1 from '../asset/new_asset/icon_web/linkedin.png';
import Sosmed2 from '../asset/new_asset/icon_web/twitter.png';
import Sosmed3 from '../asset/new_asset/icon_web/ig.png';
import Sosmed4 from '../asset/new_asset/icon_web/woilo.png';

const FooterSection = () => {
    const appContext = useContext(AppContext);
    const { t } = useTranslation();

    const desktopStyles = {
        primaryContainerStyle: {
            paddingTop: "80px",
            paddingBottom: "80px",
        },

        copyrightStyle: {
            fontFamily: appContext.fontFamily.poppins,
            fontSize: "18px",
            fontWeight: "500",
            lineHeight: "120%",
            color: appContext.colors.secondaryGrey
        },

        logoStyle: {
            width: "48px",
            height: "48px",
            marginRight: "15px"
        },

        socialMediaStyle: {
            width: "auto",
            height: "28px",

            filter: "grayscale(100%)",
        },

        downloadHeadingStyle: {
            fontFamily: appContext.fontFamily.poppins,
            fontSize: "46px",
            fontWeight: "700",
            lineHeight: "120%",
        },

        learMoreStyle: {
            width: "250px",
            textDecoration: "none",
            textAlign: "center",
            fontSize: "18px",
            fontWeight: "700",
            marginTop: "20px",
            padding: "10px 10px",
            lineHeight: "normal",
            borderRadius: "50px",
            fontFamily: appContext.fontFamily.poppins,
            color: appContext.colors.primaryWhite,
            backgroundColor: appContext.colors.primaryBlue,
        },

        footerContainerStyle: {
            textAlign: "center",
            color: appContext.colors.primaryBlack,
            marginTop: "50px"
        },

        listContainerStyle: {
            flex: "1",
            display: "flex",
            flexDirection: "column"
        },

        listTitleStyle: {
            fontWeight: "700",
            fontFamily: appContext.fontFamily.poppins,
            fontSize: "22px",
            marginTop: "20px",
            marginBottom: "20px",
            color: appContext.colors.primaryBlack
        },

        listItemStyle: {
            textDecoration: "none",
            fontWeight: "400",
            fontFamily: appContext.fontFamily.poppins,
            fontSize: "16px",
            marginBottom: "10px",
            color: appContext.colors.secondaryGrey
        },

        addressStyle: {
            textDecoration: "none",
            fontWeight: "400",
            marginTop: "10px",
            paddingLeft: "20px",
            paddingRight: "30px",
            color: appContext.colors.primaryBlack
        },
    }

    const mobileStyles = {
        primaryContainerStyle: {
            paddingTop: "80px",
            paddingBottom: "80px",
            textAlign: "center"
        },

        copyrightStyle: {
            fontFamily: appContext.fontFamily.poppins,
            fontSize: "12px",
            fontWeight: "500",
            lineHeight: "120%",
            color: appContext.colors.secondaryGrey
        },

        logoStyle: {
            width: "48px",
            height: "48px",
            marginRight: "15px"
        },

        socialMediaStyle: {
            width: "auto",
            height: "28px",
            filter: "grayscale(100%)",
        },

        downloadHeadingStyle: {
            fontFamily: appContext.fontFamily.poppins,
            fontSize: "28px",
            fontWeight: "700",
            lineHeight: "120%",
        },

        learMoreStyle: {
            width: "250px",
            textDecoration: "none",
            textAlign: "center",
            fontSize: "18px",
            fontWeight: "700",
            marginTop: "20px",
            padding: "10px 10px",
            lineHeight: "normal",
            borderRadius: "50px",
            fontFamily: appContext.fontFamily.poppins,
            color: appContext.colors.primaryWhite,
            backgroundColor: appContext.colors.primaryBlue,
        },

        footerContainerStyle: {
            textAlign: "center",
            color: appContext.colors.primaryBlack,
            marginTop: "50px"
        },

        listContainerStyle: {
            flex: "1",
            display: "flex",
            flexDirection: "column"
        },

        listTitleStyle: {
            fontWeight: "700",
            fontFamily: appContext.fontFamily.poppins,
            fontSize: "22px",
            marginTop: "20px",
            marginBottom: "20px",
            color: appContext.colors.primaryBlack
        },

        listItemStyle: {
            textDecoration: "none",
            fontWeight: "400",
            fontFamily: appContext.fontFamily.poppins,
            fontSize: "16px",
            marginBottom: "10px",
            color: appContext.colors.secondaryGrey
        },

    }

    const styles = appContext.mobileMode ? mobileStyles : desktopStyles

    const otherPageNavigate = (e) => {
        let target = e.target.id.split('_')[1];
        let url;
        if (target === "woiloads") {
            url = "https://ads.woilo.com"
        } else if (target === "creatordashboard") {
            url = "https://creator.woilo.com"
        } else if (target === "news") {
            url = "https://woilo.com/news"
        }
        window.open(url, '_blank').focus();
    }

    const linkedinLink = () => {
        window.open("https://www.linkedin.com/company/woilo/", '_blank').focus();
    }

    const woiloLink = () => {
        window.open("https://www.linkedin.com/company/woilo/", '_blank').focus();
    }

    const onDownload = () => {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        ReactGA.initialize("G-3CZYHKHHW6");
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            ReactGA.event({
                category: "download",
                action: "app_store"
            });
            window.open("https://woilo.com/appstore/ios.php?source=202", '_blank').focus();
            return;
        }
        ReactGA.event({
            category: "download",
            action: "play_store"
        });
        window.open("https://woilo.com/appstore/android.php?source=202", '_blank').focus();
    }

    const handlePageWoiloBoost = () => {
        window.open("https://boost.woilo.com");
    }

    const scrollTop = () => {
        window.scrollTo(0, 0);
    }

    return appContext.mobileMode ? (
        <>
            <Container style={styles.primaryContainerStyle}>
                <Row>
                    <Col sm={12}>
                        <div style={styles.listContainerStyle}>
                            <h2 style={styles.downloadHeadingStyle}>{t('landingPage.button.download2')}</h2>
                            <div style={styles.learMoreStyle} className='mx-auto' onClick={onDownload}> <Image src={Logo2} style={styles.logoStyle} />Play Store</div>
                            <div style={styles.learMoreStyle} className="mx-auto" onClick={onDownload}><Image src={Logo1} style={styles.logoStyle} />App Store</div>
                        </div>
                    </Col>
                    <Col sm={12}>
                        <div style={styles.listContainerStyle}>
                            <h3 style={styles.listTitleStyle}>{t('footerSection.menu')}</h3>
                            <Link style={styles.listItemStyle} onClick={scrollTop} to="/" href="/" name="home">{t('footerSection.home')}</Link>
                            <Link style={styles.listItemStyle} onClick={scrollTop} to="/contact" href="/contact" name="contact">{t('footerSection.contact')}</Link>
                            <Link style={styles.listItemStyle} onClick={onDownload}>{t('footerSection.download')}</Link>
                        </div>
                    </Col>
                    <Col sm={12}>
                        <div style={styles.listContainerStyle}>
                            <h3 style={styles.listTitleStyle}>{t('footerSection.product')}</h3>
                            {/* <Link style={styles.listItemStyle} onClick={handlePageWoiloBoost} id="navigate_boost">Woilo Boost <sup style={{ color: "red" }}>Baru!</sup></Link> */}
                            <Link style={styles.listItemStyle} onClick={otherPageNavigate} id="navigate_news">{t('navigationBar.news')}</Link>
                            <Link style={styles.listItemStyle} onClick={otherPageNavigate} id="navigate_woiloads">{t('footerSection.woilo_ads')}</Link>
                            <Link style={styles.listItemStyle} onClick={otherPageNavigate} id="navigate_creatordashboard">{t('footerSection.woilo_creator')}</Link>
                        </div>
                    </Col>
                    <Col sm={12}>
                        <div style={styles.listContainerStyle}>
                            <h3 style={styles.listTitleStyle}>{t('footerSection.information')}</h3>
                            <Link style={styles.listItemStyle} onClick={scrollTop} to="/tos" href="/tos" name="tos">{t('footerSection.term_of_use')}</Link>
                            <Link style={styles.listItemStyle} onClick={scrollTop} to="/tos" href="/tos" name="tos">{t('footerSection.privacy_policy')}</Link>
                        </div>
                    </Col>
                </Row>
            </Container>

            <Container className='text-center'>
                <Row >
                    <Row className='justify-content-start d-inline-flex'>
                        <Col md={0}><a className="ms-auto" href="https://www.linkedin.com/company/woilo/" target="_blank"><Image src={Sosmed1} style={styles.socialMediaStyle} /></a></Col>
                        <Col md={0}><a className="ms-auto" href=" https://www.woilo.com/user/woilo/" target="_blank"><Image src={Sosmed4} style={styles.socialMediaStyle} /></a></Col>
                    </Row>
                </Row>
                <Row>
                    <hr className='my-4'></hr>
                </Row>
                <Row>
                    <p style={styles.copyrightStyle}>
                        <i className="fa fa-copyright" aria-hidden="true"></i> 2023 PT Karya Digital Indo
                    </p>
                </Row>
            </Container>
        </>
    ) : (
        <>

            <Container style={styles.primaryContainerStyle}>
                <Row>
                    <Col>
                        <div style={styles.listContainerStyle}>
                            <h2 style={styles.downloadHeadingStyle}>{t('landingPage.button.download2')}</h2>
                            <Row>
                                <div style={styles.learMoreStyle} onClick={onDownload}> <Image src={Logo2} style={styles.logoStyle} />Play Store</div>
                                <div style={styles.learMoreStyle} className="ms-4" onClick={onDownload}><Image src={Logo1} style={styles.logoStyle} />App Store</div>
                            </Row>
                        </div>
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                <div style={styles.listContainerStyle}>
                                    <h3 style={styles.listTitleStyle}>{t('footerSection.menu')}</h3>
                                    <Link style={styles.listItemStyle} onClick={scrollTop} to="/" href="/" name="home">{t('footerSection.home')}</Link>
                                    <Link style={styles.listItemStyle} onClick={scrollTop} to="/contact" href="/contact" name="contact">{t('footerSection.contact')}</Link>
                                    <Link style={styles.listItemStyle} onClick={onDownload}>{t('footerSection.download')}</Link>
                                </div>
                            </Col>
                            <Col>
                                <div style={styles.listContainerStyle}>
                                    <h3 style={styles.listTitleStyle}>{t('footerSection.product')}</h3>
                                    {/* <Link style={styles.listItemStyle} onClick={handlePageWoiloBoost} id="navigate_boost">Woilo Boost <sup style={{ color: "red" }}>Baru!</sup></Link> */}
                                    <Link style={styles.listItemStyle} onClick={otherPageNavigate} id="navigate_news">{t('navigationBar.news')}</Link>
                                    <Link style={styles.listItemStyle} onClick={otherPageNavigate} id="navigate_woiloads">{t('footerSection.woilo_ads')}</Link>
                                    <Link style={styles.listItemStyle} onClick={otherPageNavigate} id="navigate_creatordashboard">{t('footerSection.woilo_creator')}</Link>
                                </div>
                            </Col>
                            <Col>
                                <div style={styles.listContainerStyle}>
                                    <h3 style={styles.listTitleStyle}>{t('footerSection.information')}</h3>
                                    <Link style={styles.listItemStyle} onClick={scrollTop} to="/tos" href="/tos" name="tos">{t('footerSection.term_of_use')}</Link>
                                    <Link style={styles.listItemStyle} onClick={scrollTop} to="/tos" href="/tos" name="tos">{t('footerSection.privacy_policy')}</Link>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

            <Container>
                <Row>
                    <Col>
                        <Row className='justify-content-start d-inline-flex'>
                            <Col md={0}><a href="https://www.linkedin.com/company/woilo/"><Image src={Sosmed1} style={styles.socialMediaStyle} /></a></Col>
                            <Col md={0}><a href=" https://www.woilo.com/user/woilo/"><Image src={Sosmed4} style={styles.socialMediaStyle} /></a></Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <hr className='my-4'></hr>
                </Row>
                <Row>
                    <p style={styles.copyrightStyle}>
                        <i className="fa fa-copyright" aria-hidden="true"></i> 2023 PT Karya Digital Indo
                    </p>
                </Row>
            </Container>
        </>
    )
}

export default FooterSection;
import { Container,Row,Col, Image, Card, Button} from "react-bootstrap"
import React, {useContext} from "react";
import NavigationBar from "../sections/NavigationBar"
import FooterSection from "../sections/FooterSection"
import { AppContext } from '../utils/AppContext';
import { useTranslation } from 'react-i18next';

// assets
import AssetHero from "../../src/asset/new_asset/woilo_boost/img_hero.png";
import AssetCardAbout1 from "../../src/asset/new_asset/woilo_boost/Icon/real_account.png";
import AssetCardAbout2 from "../../src/asset/new_asset/woilo_boost/Icon/verified.png";
import AssetCardAbout3 from "../../src/asset/new_asset/woilo_boost/Icon/real_interaction.png";
import AssetCardServices1 from "../../src/asset/new_asset/woilo_boost/Icon/instagram.png";
import AssetCardServices2 from "../../src/asset/new_asset/woilo_boost/Icon/tiktok.png";
import AssetCardServices3 from "../../src/asset/new_asset/woilo_boost/Icon/YouTube.png";
import AssetCardServices4 from "../../src/asset/new_asset/woilo_boost/Icon/survey.png";
import AssetAdvantage from "../../src/asset/new_asset/woilo_boost/img_benefit.png";
import AssetAdvantageCheck from "../../src/asset/new_asset/woilo_boost/Icon/check.png";
import AssetIconContact1 from "../../src/asset/new_asset/woilo_boost/Icon/wa.png";
import AssetIconContact2 from "../../src/asset/new_asset/woilo_boost/Icon/email.png";



export default function WoiloBoost(){
    const appContext = useContext(AppContext);
    const { t } = useTranslation();

    const desktopStyles = {

        heroSection:{
            headlineStyle: {
                fontSize: "52px",
                fontWeight: "700",
                fontFamily: appContext.fontFamily.inter,
                color: appContext.colors.secondaryBlack
            },
            subtitleStyle: {
                fontSize: "22px",
                fontWeight: "400",
                lineHeight: "120%",
                fontFamily: appContext.fontFamily.inter,
                color: appContext.colors.secondaryGrey
            },
            quoteStyle: {
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "120%",
                textDecoration: "uppercase",
                fontFamily: appContext.fontFamily.inter,
                color: appContext.colors.secondaryGrey
            },
            downloadButtonStyle: {
                textDecoration: "none",
                textAlign: "center",
                fontSize: "16px",
                fontWeight: "700",
                padding: "16px 32px",
                lineHeight: "normal",
                borderRadius: "50px",
                border: "none",
                fontFamily: appContext.fontFamily.poppins,
                color: appContext.colors.primaryWhite,
                backgroundColor: appContext.colors.primaryBlue,
            },

        },

        aboutSection:{
            headlineStyle: {
                fontSize: "46px",
                fontWeight: "700",
                fontFamily: appContext.fontFamily.inter,
                color: appContext.colors.secondaryBlack
            },
            subtitleStyle: {
                fontSize: "28px",
                fontWeight: "600",
                lineHeight: "120%",
                fontFamily: appContext.fontFamily.inter,
                color: appContext.colors.secondaryBlack
            },
            descriptionStyle: {
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "120%",
                fontFamily: appContext.fontFamily.inter,
                color: appContext.colors.secondaryGrey
            },
            cardTitleStyle:{
                fontSize: "28px",
                fontWeight: "600",
                lineHeight: "100%",
                fontFamily: appContext.fontFamily.inter,
                color: appContext.colors.primaryBlue  
            },
            cardDescriptionStyle:{
                fontSize: "18px",
                fontWeight: "400",
                lineHeight: "120%",
                fontFamily: appContext.fontFamily.inter,
                color: appContext.colors.secondaryGrey  
            },
            iconAboutStyle:{
                width: "auto",
                height: "48px"
            }

        },

        servicesSection:{
            servicesContainerStyle:{
                background: "#EBF3FE"
            },
            headlineStyle: {
                fontSize: "46px",
                fontWeight: "700",
                fontFamily: appContext.fontFamily.inter,
                color: appContext.colors.secondaryBlack
            },
            descriptionStyle: {
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "120%",
                fontFamily: appContext.fontFamily.inter,
                color: appContext.colors.secondaryGrey
            },
            cardStyle:{
                borderRadius:"20px",
                padding: "16px 20px",
                textAlign: "center",
            },
            cardTitle:{
                fontSize: "22px",
                fontWeight: "600",
                lineHeight: "120%",
                fontFamily: appContext.fontFamily.inter,
                color: appContext.colors.secondaryBlack
            },
            cardDescription:{
                fontSize: "18px",
                fontWeight: "400",
                lineHeight: "120%",
                fontFamily: appContext.fontFamily.inter,
                color: appContext.colors.secondaryGrey
            },
            cardIcon:{
                width: "50px",
                height: "50px",
                margin: "20px auto"
            }
        },

        AdvantageSection:{
            headlineStyle: {
                fontSize: "46px",
                fontWeight: "700",
                fontFamily: appContext.fontFamily.inter,
                color: appContext.colors.secondaryBlack
            },
            descriptionStyle: {
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "120%",
                fontFamily: appContext.fontFamily.inter,
                color: appContext.colors.secondaryGrey
            },
            ulStyle:{
                listStyle: "none",
                
            },
            litStyle:{
                marginBottom: "40px",
                display:"inline-flex",
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "120%",
                fontFamily: appContext.fontFamily.inter,
                color: appContext.colors.secondaryGrey
            }
        },
        contactSection:{
            contactContainerStyle:{
                background: "#EBF3FE"
            },
            headlineStyle: {
                fontSize: "46px",
                fontWeight: "700",
                fontFamily: appContext.fontFamily.inter,
                color: appContext.colors.secondaryBlack
            },
            descriptionStyle: {
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "120%",
                fontFamily: appContext.fontFamily.inter,
                color: appContext.colors.secondaryGrey
            },
            buttonStyle: {
                backgroundColor: appContext.colors.primaryBlue,
                borderRadius: "30px",
                padding: "12px 35px",
                margin: "30px",
                fontWeight: "600",
                fontSize: "18px",
                fontFamily: appContext.fontFamily.poppins,
            }
        },

        subtitleStyle: {
            textAlign: "center",
            fontSize: "22px",
            fontWeight: "500",
            lineHeight: "120%",
            fontFamily: appContext.fontFamily.inter,
            color: appContext.colors.secondaryGrey
        },

        imageAsset1: {
            width: "100%",
            padding: "0px 100px"
        },

        downloadButtonStyle: {
            textDecoration: "none",
            textAlign: "center",
            fontSize: "16px",
            fontWeight: "700",
            marginTop: "20px",
            padding: "18px 32px",
            lineHeight: "normal",
            borderRadius: "50px",
            fontFamily: appContext.fontFamily.poppins,
            color: appContext.colors.primaryWhite,
            backgroundColor: appContext.colors.primaryBlue,
        },
    }

    const mobileStyles = {
        heroSection:{
            headlineStyle: {
                textAlign: "center",
                fontSize: "52px",
                fontWeight: "700",
                fontFamily: appContext.fontFamily.inter,
                color: appContext.colors.secondaryBlack
            },
            subtitleStyle: {
                textAlign: "center",
                fontSize: "22px",
                fontWeight: "400",
                lineHeight: "120%",
                fontFamily: appContext.fontFamily.inter,
                color: appContext.colors.secondaryGrey
            },
            quoteStyle: {
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "120%",
                textDecoration: "uppercase",
                fontFamily: appContext.fontFamily.inter,
                color: appContext.colors.secondaryGrey
            },
            downloadButtonStyle: {
                textDecoration: "none",
                textAlign: "center",
                fontSize: "16px",
                fontWeight: "700",
                padding: "16px 32px",
                lineHeight: "normal",
                borderRadius: "50px",
                border: "none",
                margin: "10px auto",
                fontFamily: appContext.fontFamily.poppins,
                color: appContext.colors.primaryWhite,
                backgroundColor: appContext.colors.primaryBlue,
            },

        },

        aboutSection:{
            headlineStyle: {
                fontSize: "24px",
                fontWeight: "700",
                fontFamily: appContext.fontFamily.inter,
                color: appContext.colors.secondaryBlack
            },
            subtitleStyle: {
                fontSize: "24px",
                fontWeight: "600",
                lineHeight: "120%",
                fontFamily: appContext.fontFamily.inter,
                color: appContext.colors.secondaryBlack
            },
            descriptionStyle: {
                fontSize: "18px",
                fontWeight: "400",
                lineHeight: "120%",
                fontFamily: appContext.fontFamily.inter,
                color: appContext.colors.secondaryGrey
            },
            cardTitleStyle:{
                fontSize: "18px",
                fontWeight: "600",
                lineHeight: "100%",
                fontFamily: appContext.fontFamily.inter,
                color: appContext.colors.primaryBlue  
            },
            cardDescriptionStyle:{
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "120%",
                fontFamily: appContext.fontFamily.inter,
                color: appContext.colors.secondaryGrey  
            },
            iconAboutStyle:{
                width: "auto",
                height: "48px"
            }

        },

        servicesSection:{
            servicesContainerStyle:{
                background: "#EBF3FE"
            },
            headlineStyle: {
                fontSize: "28px",
                fontWeight: "700",
                fontFamily: appContext.fontFamily.inter,
                color: appContext.colors.secondaryBlack
            },
            descriptionStyle: {
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "120%",
                fontFamily: appContext.fontFamily.inter,
                color: appContext.colors.secondaryGrey
            },
            cardStyle:{
                borderRadius:"20px",
                padding: "16px 20px",
                textAlign: "center",
                margin: "10px 20px"
            },
            cardTitle:{
                fontSize: "18px",
                fontWeight: "600",
                lineHeight: "120%",
                fontFamily: appContext.fontFamily.inter,
                color: appContext.colors.secondaryBlack
            },
            cardDescription:{
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "120%",
                fontFamily: appContext.fontFamily.inter,
                color: appContext.colors.secondaryGrey
            },
            cardIcon:{
                width: "50px",
                height: "50px",
                margin: "20px auto"
            }
        },

        AdvantageSection:{
            headlineStyle: {
                fontSize: "46px",
                fontWeight: "700",
                fontFamily: appContext.fontFamily.inter,
                color: appContext.colors.secondaryBlack
            },
            descriptionStyle: {
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "120%",
                fontFamily: appContext.fontFamily.inter,
                color: appContext.colors.secondaryGrey
            },
            ulStyle:{
                listStyle: "none",
                
            },
            litStyle:{
                paddingRight: "20px",
                marginBottom: "40px",
                display:"inline-flex",
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "120%",
                fontFamily: appContext.fontFamily.inter,
                color: appContext.colors.secondaryGrey
            }
        },

        contactSection:{
            contactContainerStyle:{
                background: "#EBF3FE"
            },
            headlineStyle: {
                fontSize: "28px",
                fontWeight: "700",
                fontFamily: appContext.fontFamily.inter,
                color: appContext.colors.secondaryBlack
            },
            descriptionStyle: {
                fontSize: "18px",
                fontWeight: "400",
                lineHeight: "120%",
                fontFamily: appContext.fontFamily.inter,
                color: appContext.colors.secondaryGrey
            },
            buttonStyle: {
                backgroundColor: appContext.colors.primaryBlue,
                borderRadius: "30px",
                padding: "12px 35px",
                margin: "10px 30px",
                fontWeight: "600",
                fontSize: "18px",
                fontFamily: appContext.fontFamily.poppins,
            }
        },

    }

    const styles = appContext.mobileMode ? mobileStyles : desktopStyles

    function onContact(e){
        console.log(e)
        if(e === 1){
            window.open("https://wa.link/96dnok", '_blank');
            return;
        } else if (e === 2){
            window.open("mailto:business@woilo.com?subject='Hello Admin! Woilo Boost'", '_blank');
            return;
        }
    }
    
    return (<>
    <NavigationBar/>
    {/* Section Hero */}
        <Container className="py-5">
            <Row className="py-3">
                <Col md={6} >
                    <h1 style={styles.heroSection.headlineStyle}>{t('woiloBoostPage.heroSection.title')}</h1>
                    <p className="mt-4" style={styles.heroSection.subtitleStyle}>{t('woiloBoostPage.heroSection.subtitle')}</p>
                    <div className={appContext.mobileMode? "text-center" : "text-left"} >
                        <button className="mt-2 align-self-center" style={styles.heroSection.downloadButtonStyle} onClick={() => {onContact(1)}} >
                            {t('woiloBoostPage.heroSection.button')}
                        </button>
                    </div>
                </Col>
                <Col md={6} className="text-center">
                    <Image src={AssetHero}  height={appContext.mobileMode? 350 : 650} width="auto" alt="Hero Woilo Boost" />
                </Col>
            </Row>
            <Row className="py-3">
                <h2 className="text-uppercase text-center mt-4" style={styles.heroSection.quoteStyle}>{t('woiloBoostPage.heroSection.quote')}</h2>
            </Row>
        </Container>
    {/* End Section Hero */}

    {/* Section About */}
        <Container className="py-5">
            <Row className="text-center py-3">
                <h1 style={styles.aboutSection.headlineStyle}>{t('woiloBoostPage.aboutSection.title')}</h1>
            </Row>
            <Row className="py-4">
                <Col md={4} className={appContext.mobileMode ? "px-5 py-2" : "p-4"}>
                    <div className="d-inline-flex align-middle">
                         <Image style={styles.aboutSection.iconAboutStyle} src={AssetCardAbout1} />
                         <h3 className="my-auto mx-2" style={styles.aboutSection.cardTitleStyle}>{t('woiloBoostPage.aboutSection.card1.title')}</h3>
                    </div>
                    <p style={styles.aboutSection.cardDescriptionStyle}>{t('woiloBoostPage.aboutSection.card1.description')}</p>
                </Col>
                <Col md={4} className={appContext.mobileMode ? "px-5 py-2" : "p-4"}>
                    <div className="d-inline-flex align-middle">
                         <Image style={styles.aboutSection.iconAboutStyle} src={AssetCardAbout2} />
                         <h3 className="my-auto mx-2" style={styles.aboutSection.cardTitleStyle}>{t('woiloBoostPage.aboutSection.card2.title')}</h3>
                    </div>
                    <p style={styles.aboutSection.cardDescriptionStyle}>{t('woiloBoostPage.aboutSection.card2.description')}</p>
                </Col>
                <Col md={4} className={appContext.mobileMode ? "px-5 py-2" : "p-4"}>
                    <div className="d-inline-flex align-middle">
                         <Image style={styles.aboutSection.iconAboutStyle} src={AssetCardAbout3} />
                         <h3 className="my-auto mx-2" style={styles.aboutSection.cardTitleStyle}>{t('woiloBoostPage.aboutSection.card3.title')}</h3>
                    </div>
                    <p style={styles.aboutSection.cardDescriptionStyle}>{t('woiloBoostPage.aboutSection.card3.description')}</p>
                </Col>
            </Row>
            <Row className="text-center py-5 ">
                <h2 className="text-uppercase" style={styles.aboutSection.subtitleStyle}>{t('woiloBoostPage.aboutSection.subtitle')}</h2>
                <p className={appContext.mobileMode ? "px-2" : "px-5"} style={styles.aboutSection.descriptionStyle}>{t('woiloBoostPage.aboutSection.description')}</p>
            </Row>
        </Container>
    {/* End Section About */}

    {/* Section Services */}
    <section style={styles.servicesSection.servicesContainerStyle}>
        <Container className="py-5" >
            <Row className="text-center py-3">
                <h1 className="mb-2"style={styles.servicesSection.headlineStyle}>{t('woiloBoostPage.servicesSection.title')}</h1>
                <p className="mb-3" style={styles.servicesSection.descriptionStyle}>{t('woiloBoostPage.servicesSection.description')}</p>
            </Row>
            <Row className="py-2">
                <Col md={3}>
                    <Card style={styles.servicesSection.cardStyle}>
                        <Image style={styles.servicesSection.cardIcon} src={AssetCardServices1} />
                        <h3 style={styles.servicesSection.cardTitle}>Like, Comment, Share</h3>
                        <p style={styles.servicesSection.cardDescription}>Instagram Post</p>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card style={styles.servicesSection.cardStyle}>
                        <Image style={styles.servicesSection.cardIcon} src={AssetCardServices2} />
                        <h3 style={styles.servicesSection.cardTitle}>Like, Comment, Share</h3>
                        <p style={styles.servicesSection.cardDescription}>Tiktok Video</p>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card style={styles.servicesSection.cardStyle}>
                        <Image style={styles.servicesSection.cardIcon} src={AssetCardServices3} />
                        <h3 style={styles.servicesSection.cardTitle}>Like, Comment, Share</h3>
                        <p style={styles.servicesSection.cardDescription}>Youtube Video</p>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card style={styles.servicesSection.cardStyle}>
                        <Image style={styles.servicesSection.cardIcon} src={AssetCardServices4} />
                        <h3 style={styles.servicesSection.cardTitle}>Isi Data</h3>
                        <p style={styles.servicesSection.cardDescription}>Survey</p>
                    </Card>
                </Col>
            </Row>
        </Container>
    </section>
    {/* End Section Services */}
    
    {/* Section Advantage */}
    <Container className="py-5">
        <Row className="text-center py-3">
            <h1 className="mb-5 text-capitalize" style={styles.servicesSection.headlineStyle}>{t('woiloBoostPage.advantageSection.title')}</h1> 
        </Row>
        <Row className="py-3">
            <Col md={6} className="my-auto">
                <ul style={styles.AdvantageSection.ultStyle}>
                    <li className="align-middle" style={styles.AdvantageSection.litStyle}> <Image className="me-2" height="20px" src={AssetAdvantageCheck} />{t('woiloBoostPage.advantageSection.poin1')} </li>
                    <li className="align-middle" style={styles.AdvantageSection.litStyle}> <Image className="me-2" height="20px" src={AssetAdvantageCheck} />{t('woiloBoostPage.advantageSection.poin2')} </li>
                    <li className="align-middle" style={styles.AdvantageSection.litStyle}> <Image className="me-2" height="20px" src={AssetAdvantageCheck} />{t('woiloBoostPage.advantageSection.poin3')} </li>
                    <li className="align-middle" style={styles.AdvantageSection.litStyle}> <Image className="me-2" height="20px" src={AssetAdvantageCheck} />{t('woiloBoostPage.advantageSection.poin4')} </li>
                </ul>
            </Col>
            <Col md={6} className="text-center">
                <Image height={appContext.mobileMode? 350 : 550} width="auto" src={AssetAdvantage} />
            </Col>
        </Row>
    </Container>
    {/* End Section Advantage */}

    {/* Section Contact */}
    <section style={styles.contactSection.contactContainerStyle}>
        <Container className="py-5" >
            <Row className="text-center py-3">
                <h1 className="mb-3  text-capitalize"style={styles.contactSection.headlineStyle}>{t('woiloBoostPage.contactSection.title')}</h1>
                <p className="mb-3" style={styles.contactSection.descriptionStyle}>{t('woiloBoostPage.contactSection.description')}</p>
                <div className="mx-auto">
                    <Button size="lg" onClick={() => {onContact(1)}} variant="primary" style={styles.contactSection.buttonStyle}>
                        WhatsApp <Image height="28px" className="ms-2" src={AssetIconContact1} />
                    </Button>
                    <Button  onClick={() => {onContact(2)}} size="lg"variant="primary" style={styles.contactSection.buttonStyle}>
                        Email<Image height="28px" className="ms-2" src={AssetIconContact2} /> 
                    </Button>
                </div>
            </Row>
        </Container>
    </section>
    {/* End Section Contact */}
    <FooterSection/>
    </>)
};


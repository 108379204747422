import React, { useContext, useState } from 'react'
import { Row, Col, Form, Button, Card } from 'react-bootstrap';
import Logo from '../asset/logo_woilo_full.svg';
import WoiloIcon from '../asset/woilo_icon.svg';
import { AppContext } from "../utils/AppContext";
import $, { ajax } from 'jquery';
import UrlHouse from '../utils/UrlHouse';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const ContactUsPage = () => {
    const { t } = useTranslation();
    const appContext = useContext(AppContext);
    const navigate = useNavigate();
    const [ isInstitution, setIsInstitution ] = useState(false);
    const [ inquiryData, setInquiryData ] = useState({
        email: "",
        fullName: "",
        type: "",
        isInstitution: "",
        institutionName: "",
        institutionWebsite: "",
        message: ""
    });

    const [ warningShow, setWarningShow ] = useState(false);

    const styles = {
        outerContainerStyle: {
            
        },

        businessInquiryFormContainerStyle: {
            padding: "0px 10%",
            marginTop: "50px",
            marginBottom: "50px"
        },

        businessInquiryFormRadioLabelStyle: {
            paddingTop: "0px"
        },

        inputInstitutionDataFormStyle: {
            display: isInstitution ? "flex" : "none"
        },

        sendButtonStyle: {
            float: "right",
            width: appContext.mobileMode?"100%":"25%",
            padding: "15px"
        },

        contentContainerStyle: {
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            height: "100%",
            width: "100%",
        },

        logoTopLeftContainerStyle: {
            position: "absolute",
            top: "20px",
            left: "20px"
        },

        ellipsUpperRightStyle: {
            position: "absolute",
            width: "75%",
            height: "75%",
            right: "-25%",
            top: "-25%",
            background: "#FF9E80",
            opacity: "0.4",
            filter: "blur(400px)"
        },

        ellipsBottomLeftStyle: {
            position: "absolute",
            width: "75%",
            height: "75%",
            left: "-25%",
            bottom: "-25%",
            background: "#2E80EB",
            opacity: "0.4",
            filter: "blur(400px)",
        },

        footerContainerStyle: {
            position: "relative",
            bottom: "0px",
            width: "100%",
            padding: "0px 30px",
        },

        socialIconsContainerStyle: {
            marginTop: "50px"
        },

        socialIconsStyle: {
            fontSize: "2rem",
            marginRight: "15px",
            cursor: "pointer",
            color: appContext.multiModeStyle.secondaryTextColor,
        },

        warningCardStyle: {
            padding: "20px",
            marginBottom: "20px",
            fontWeight: "500",
            color: "#FF3D00", 
            backgroundColor: "#FFD8CC",
            display: warningShow ? "block" : "none"
        }
    }

    const socialMediaIconClick = (e) => {
        let target = e.target.id.split('_')[1];
        let url;
        if(target === "linkedin"){
            url = "https://www.linkedin.com/company/woilo"
        }else if(target === "twitter"){
            url = "https://www.twitter.com/woilo_id"
        }else if(target === "instagram"){
            url = "https://instagram.com/woilo_id"
        }else{
            url = "https://woilo.com/user/woilo"
        }
        window.open(url, '_blank').focus();
    }

    const onInstitutionCheck = (e) => {
        let value = e.target.value;
        setIsInstitution(value === "1")
        setInquiryData({
            ...inquiryData, 
            isInstitution: value, 
            institutionName: value === "1" ? inquiryData.institutionName : "",
            institutionWebsite: value === "1" ? inquiryData.institutionWebsite : ""
        });
    }

    const onFormChange = (e) => {
        let target = e.target.name;
        let value = e.target.value;
        if(target === "input_email"){
            setInquiryData({
                ...inquiryData,
                email: value
            })
        }else if(target === "input_full_name"){
            setInquiryData({
                ...inquiryData,
                fullName: value
            })
        }else if(target === "input_type"){
            setInquiryData({
                ...inquiryData,
                type: value
            })
        }else if(target === "input_institution_name"){
            setInquiryData({
                ...inquiryData,
                institutionName: value
            })
        }else if(target === "input_institution_website"){
            setInquiryData({
                ...inquiryData,
                institutionWebsite: value
            })
        }else if(target === "input_message"){
            setInquiryData({
                ...inquiryData,
                message: value
            })
        }
    }

    const onSendButtonClick = () => {
        let id = inquiryData;
        let check = isInstitution ? (id.institutionName !== "" || id.institutionWebsite !== "") : true;
        if(id.email !== "" && id.fullName !== "" && id.message !== "" && id.type !== "" && id.isInstitution !== ""){
            if(check){
                let inquiryDataJson = JSON.stringify(inquiryData);
                $.ajax({
                    type: "POST",
                    url: UrlHouse.CONTACT_US,
                    data: {
                        inquiry_data: inquiryDataJson
                    },
                    dataType: "json",
                    success: function (response) {
                        if(response.result === 1){
                            window.alert(t('contactPage.success'));
                            navigate('/');
                        }else{
                            window.alert(t('contactPage.failed'))
                        }
                    },
                    error: function(e){
                        console.warn(e);
                        window.alert(t('contactPage.failed'))
                    }
                });
            }
        }else{
            setWarningShow(true);
        }
    }

    return(
        <>
            <div style={styles.contentContainerStyle}>
                <div style={styles.businessInquiryFormContainerStyle}>
                    <h2>{t('contactPage.contact_us')}</h2>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>{t('contactPage.email')}</Form.Label>
                            <Form.Control onChange={onFormChange} name="input_email" type="email"/>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>{t('contactPage.full_name')}</Form.Label>
                            <Form.Control onChange={onFormChange} name="input_full_name" type="text"/>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label as="legend" column sm={2} style={styles.businessInquiryFormRadioLabelStyle}>
                                {t('contactPage.inquiry')}
                            </Form.Label>
                            <Col sm={4}>
                                <Form.Check
                                    type="radio"
                                    label={t('contactPage.inquiry_1')}
                                    onChange={onFormChange}
                                    name="input_type"
                                    value="kerjasama"
                                />
                                <Form.Check
                                    type="radio"
                                    label={t('contactPage.inquiry_2')}
                                    onChange={onFormChange}
                                    name="input_type"
                                    value="investasi"
                                />
                                <Form.Check
                                    type="radio"
                                    label={t('contactPage.inquiry_3')}
                                    onChange={onFormChange}
                                    name="input_type"
                                    value="lainnya"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label as="legend" column sm={2} style={styles.businessInquiryFormRadioLabelStyle}>
                                {t('contactPage.entity')}
                            </Form.Label>
                            <Col sm={4}>
                                <Form.Check
                                    type="radio"
                                    label={t('contactPage.entity_1')}
                                    name="input_institution"
                                    value={0}
                                    onChange={onInstitutionCheck}
                                />
                                <Form.Check
                                    type="radio"
                                    label={t('contactPage.entity_2')}
                                    name="input_institution"
                                    value={1}
                                    onChange={onInstitutionCheck}
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group style={styles.inputInstitutionDataFormStyle} className="mb-3" as={Row}>
                            <Col md={6}>
                                <Form.Label>{t('contactPage.company_name')}</Form.Label>
                                <Form.Control onChange={onFormChange} name="input_institution_name" type="text"/>
                            </Col>
                            <Col md={6}>
                                <Form.Label>{t('contactPage.company_website')}</Form.Label>
                                <Form.Control onChange={onFormChange} name="input_institution_website" type="text"/>
                            </Col>
                        </Form.Group>
                        
                        <Form.Group className="mb-3">
                            <Form.Label>{t('contactPage.message')}</Form.Label>
                            <Form.Control onChange={onFormChange} name="input_message" as="textarea" rows={3} />
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Col sm={{ span: 12}}>
                                <Form.Check label={t('contactPage.term_check')} />
                            </Col>
                        </Form.Group>
                        <Card id="warning_card" style={styles.warningCardStyle}>
                            {t('contactPage.error')}
                        </Card>
                        <Form.Group as={Row} className="mb-3">
                            <Col sm={{ span: 12}}>
                                <Button onClick={onSendButtonClick} style={styles.sendButtonStyle}>{t('contactPage.send')}</Button>
                            </Col>
                        </Form.Group>
                    </Form>
                </div>
                
            </div>
        </>
    )
}

export default ContactUsPage;
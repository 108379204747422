import React, { useContext } from 'react';
import { Link } from "react-router-dom";
import { Image } from 'react-bootstrap';
import { AppContext } from '../../utils/AppContext';
import { useTranslation } from 'react-i18next';
import ReactGA from "react-ga4";
import Asset1 from '../../asset/section1-asset.png';

const Section1 = () => {
    const appContext = useContext(AppContext);
    const { t } = useTranslation();

    const desktopStyles = {
        contentContainerStyle: {
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            //height: (window.innerHeight*9/10)+"px",
            padding: "40px",
            backgroundColor: appContext.colors.primaryWhite
        },

        headlineStyle: {
            textAlign: "center",
            fontSize: "72px",
            fontWeight: "700",
            fontFamily: appContext.fontFamily.poppins,
            color: appContext.colors.secondaryBlack
        },

        subtitleStyle: {
            textAlign: "center",
            fontSize: "22px",
            fontWeight: "500",
            lineHeight: "120%",
            fontFamily: appContext.fontFamily.inter,
            color: appContext.colors.secondaryGrey
        },

        imageAsset1: {
            width: "100%",
            padding: "0px 100px"
        },

        downloadButtonStyle: {
            textDecoration: "none",
            textAlign: "center",
            fontSize: "16px",
            fontWeight: "700",
            marginTop: "20px",
            padding: "18px 32px",
            lineHeight: "normal",
            borderRadius: "50px",
            fontFamily: appContext.fontFamily.poppins,
            color: appContext.colors.primaryWhite,
            backgroundColor: appContext.colors.primaryBlue,
        },
    }

    const mobileStyles = {
        contentContainerStyle: {
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            //height: (window.innerHeight*9/10)+"px",
            padding: "30px",
            backgroundColor: appContext.colors.primaryWhite
        },

        headlineStyle: {
            textAlign: "center",
            fontSize: "48px",
            fontWeight: "700",
            fontFamily: appContext.fontFamily.poppins,
            color: appContext.colors.secondaryBlack
        },

        subtitleStyle: {
            textAlign: "center",
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: "140%",
            marginTop: "14px",
            fontFamily: appContext.fontFamily.inter,
            color: appContext.colors.secondaryGrey
        },

        downloadButtonStyle: {
            textDecoration: "none",
            textAlign: "center",
            fontSize: "16px",
            fontWeight: "700",
            marginTop: "20px",
            padding: "18px 32px",
            lineHeight: "normal",
            borderRadius: "50px",
            fontFamily: appContext.fontFamily.poppins,
            color: appContext.colors.primaryWhite,
            backgroundColor: appContext.colors.primaryBlue,
        },
        imageAsset1: {
            width: "100%",
            marginTop: "20px"
        },

    }

    const styles = appContext.mobileMode ? mobileStyles : desktopStyles

    const scrollView = () => {
        document.getElementsByTagName('rating_section')[0].scrollIntoView({ behavior: 'smooth' });
    }

    const onDownload = () => {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        ReactGA.initialize("G-3CZYHKHHW6");
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            ReactGA.event({
                category: "download",
                action: "app_store"
            });
            window.open("https://woilo.com/appstore/ios.php?source=202", '_blank').focus();
            return;
        }
        ReactGA.event({
            category: "download",
            action: "play_store"
        });
        window.open("https://woilo.com/appstore/android.php?source=202", '_blank').focus();
    }

    return (
        <>
            <div style={styles.contentContainerStyle}>
                <h1 style={styles.headlineStyle}>{t('landingPage.section1.title')}</h1>
                <h3 style={styles.subtitleStyle}>
                    {t('landingPage.section1.subtitle')}
                </h3>
                <Link style={styles.downloadButtonStyle} onClick={onDownload}>
                    {t('landingPage.button.download2')}
                </Link>
                <Image style={styles.imageAsset1} src={Asset1} />

            </div>
        </>
    )
}

export default Section1;
import React, { useContext } from 'react';
import { AppContext } from '../../utils/AppContext';
import { useTranslation } from 'react-i18next';
import ReactGA from "react-ga4";

const Section5 = () => {
    const appContext = useContext(AppContext);
    const { t } = useTranslation();

    const desktopStyles = {
        contentContainerStyle: {
            //height: (window.innerHeight*9/10)+"px",
            display: "flex",
            padding: "30px",
            backgroundColor: appContext.colors.primaryWhite
        },

        headlineStyle: {
            fontSize: "40px",
            fontWeight: "700",
            color: appContext.colors.primaryBlack
        },

        subtitleStyle: {
            fontSize: "20px",
            fontWeight: "400",
            color: appContext.colors.primaryBlack
        },

        captionContainerStyle: {
            flex: "1",
            textAlign: "end",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            flexDirection: "column",
            padding: "20px",
            width: (window.innerWidth/2 - 30)+"px",
        },

        videoStyle: {
            flex: "1",
            paddingLeft: (window.innerWidth/12)+"px",
            paddingRight: (window.innerWidth/12)+"px",
            paddingBottom: (window.innerWidth/12)+"px",
            width: (window.innerWidth/2 - 30)+"px",
            marginTop: "50px"
        },

        learMoreStyle: {
            width: "300px",
            textAlign: "center",
            fontSize: "20px",
            fontWeight: "600",
            marginTop: "20px",
            marginLeft: "auto", 
            marginRight: "0",
            paddingTop: "10px",
            paddingBottom: "10px",
            borderRadius: "10px",
            backgroundColor: appContext.colors.primaryBlue,
            color: appContext.colors.primaryWhite,
        }
    }

    const mobileStyles = {
        contentContainerStyle: {
            //height: (window.innerHeight*9/10)+"px",
            padding: "30px",
            backgroundColor: appContext.colors.primaryWhite
        },

        headlineStyle: {
            fontSize: "30px",
            fontWeight: "700",
            marginTop: "20px",
            marginLeft: "30px",
            marginRight: "30px",
            textAlign: "center",
            color: appContext.colors.primaryBlack
        },

        subtitleStyle: {
            fontSize: "15px",
            fontWeight: "500",
            marginLeft: "30px",
            marginRight: "30px",
            textAlign: "center",
            color: appContext.colors.primaryBlack
        },

        videoStyle: {
            width: (window.innerWidth - 60)+"px",
            marginTop: "50px"
        },

        learMoreStyle: {
            width: (window.innerWidth - 120)+"px",
            textAlign: "center",
            fontSize: "20px",
            fontWeight: "600",
            marginTop: "20px",
            marginLeft: "30px",
            marginRight: "30px",
            paddingTop: "10px",
            paddingBottom: "10px",
            borderRadius: "10px",
            backgroundColor: appContext.colors.primaryBlue,
            color: appContext.colors.primaryWhite,
        }
    }

    const styles = appContext.mobileMode ? mobileStyles : desktopStyles

    const onDownload = () => {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        ReactGA.initialize("G-3CZYHKHHW6");
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            ReactGA.event({
                category: "download",
                action: "app_store"
            });
            window.open("https://woilo.com/appstore/ios.php?source=202", '_blank').focus();
            return;
        }
        ReactGA.event({
            category: "download",
            action: "play_store"
        });
        window.open("https://woilo.com/appstore/android.php?source=202", '_blank').focus();
    }

    return appContext.mobileMode?(
        <>
            <div style={styles.contentContainerStyle}>
                <video style={styles.videoStyle} autoPlay loop muted playsInline>
                    <source src="https://woilo.com/contents/features.webm" type="video/webm"/>
                    <source src="https://woilo.com/contents/features.mp4" type="video/mp4"/>
                </video>
                <h1 style={styles.headlineStyle}>Semuanya Ada di Woilo</h1>
                <div style={styles.subtitleStyle}>
                    Cari Teman, Jadi Content Creator, Buat dan Jual NFT, Ngobrol dengan Teman, Sumber Informasi, dan Uang Jajan
                </div>
                <div style={styles.learMoreStyle} onClick={onDownload}>
                    Coba Sekarang
                </div>
            </div>
        </>
    ):(
        <>
            <div style={styles.contentContainerStyle}>
                <div style={styles.captionContainerStyle}>
                    <h1 style={styles.headlineStyle}>Semuanya Ada di Woilo</h1>
                    <div style={styles.subtitleStyle}>
                        Cari Teman, Jadi Content Creator, Buat dan Jual NFT, Ngobrol dengan Teman, Sumber Informasi, dan Uang Jajan
                    </div>
                    <div style={styles.learMoreStyle} onClick={onDownload}>
                        Coba Sekarang
                    </div>
                </div>
                <video style={styles.videoStyle} autoPlay loop muted playsInline>
                    <source src="https://woilo.com/contents/features.webm" type="video/webm"/>
                    <source src="hhttps://woilo.com/contents/features.mp4" type="video/mp4"/>
                </video>
            </div>
        </>
    )
}

export default Section5;
import React from "react";
import { useTranslation } from "react-i18next";

const WoiloCoin = (props) => {
    const styles = props.styles;
    const { t } = useTranslation();

    return(
        <>
            <p style={styles.tosContentLastChangedStyle}>{t('termsOfServicePage.lastChanged')}</p>
            <p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.woiloCoin.subtitle')}</p>
            <ul >
                <li style={styles.tosListBulletStyle}><p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.woiloCoin.termList.li1')}</p></li>
                <li style={styles.tosListBulletStyle}><p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.woiloCoin.termList.li2')}</p></li>
                <li style={styles.tosListBulletStyle}><p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.woiloCoin.termList.li3')}</p></li>
                <li style={styles.tosListBulletStyle}><p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.woiloCoin.termList.li4')}</p></li>
            </ul>
            <h3 style={styles.tosContentTitleStyle}>{t('termsOfServicePage.changesDisclaimer.title')}</h3>
            <p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.changesDisclaimer.content.p1')}</p>
            <p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.changesDisclaimer.content.p2')}</p>
            <p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.changesDisclaimer.content.p3')}</p>
        </>
    )
}

export default WoiloCoin
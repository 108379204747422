import React, { useContext } from 'react';
import { Col, Container, Row, Carousel } from 'react-bootstrap';
import { AppContext } from '../../utils/AppContext';
import { useTranslation } from 'react-i18next';
import ReactGA from "react-ga4";

import Video1 from "../../asset/new_asset/animation_video/feature.mp4"
import Video2 from "../../asset/new_asset/animation_video/money.mp4"
import Video3 from "../../asset/new_asset/animation_video/popular.mp4"
import Video4 from "../../asset/new_asset/animation_video/scrolling.mp4"

const CarouselSection = () => {
    const appContext = useContext(AppContext);
    const { t } = useTranslation();

    const desktopStyles = {
        containerStyle: {
            backgroundColor: appContext.colors.primaryWhite,
        },

        headlineStyle: {
            fontSize: "46px",
            fontWeight: "700",
            fontFamily: appContext.fontFamily.inter,
            color: appContext.colors.primaryBlack,
            lineHeight: "120%"
        },

        subtitleStyle: {
            fontSize: "22px",
            fontWeight: "400",
            fontFamily: appContext.fontFamily.inter,
            color: "#959595",
            marginTop: "20px",
            lineHeight: "120%"
        },

        videoStyle: {
            height: "600px",
            marginTop: "60px"
        },

        learMoreStyle: {
            width: "300px",
            textDecoration: "none",
            textAlign: "center",
            fontSize: "16px",
            fontWeight: "700",
            marginTop: "20px",
            padding: "18px 32px",
            lineHeight: "normal",
            borderRadius: "50px",
            fontFamily: appContext.fontFamily.poppins,
            color: appContext.colors.primaryWhite,
            backgroundColor: appContext.colors.primaryBlue,
        }
    }

    const mobileStyles = {
        containerStyle: {
            backgroundColor: appContext.colors.primaryWhite,
        },

        headlineStyle: {
            fontSize: "28px",
            fontWeight: "700",
            fontFamily: appContext.fontFamily.inter,
            color: appContext.colors.primaryBlack,
            lineHeight: "120%"
        },

        subtitleStyle: {
            fontSize: "16px",
            fontWeight: "400",
            fontFamily: appContext.fontFamily.inter,
            color: "#959595",
            marginTop: "20px",
            lineHeight: "120%"
        },

        videoStyle: {
            width: "100%",
            marginTop: "60px"
        },

        learMoreStyle: {
            maxWidth: "200px",
            textDecoration: "none",
            textAlign: "center",
            fontSize: "14px",
            fontWeight: "700",
            marginTop: "20px",
            padding: "18px 32px",
            lineHeight: "normal",
            borderRadius: "50px",
            fontFamily: appContext.fontFamily.poppins,
            color: appContext.colors.primaryWhite,
            backgroundColor: appContext.colors.primaryBlue,
        }
    }

    const styles = appContext.mobileMode ? mobileStyles : desktopStyles

    const onDownload = () => {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        ReactGA.initialize("G-3CZYHKHHW6");
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            ReactGA.event({
                category: "download",
                action: "app_store"
            });
            window.open("https://woilo.com/appstore/ios.php?source=202", '_blank').focus();
            return;
        }
        ReactGA.event({
            category: "download",
            action: "play_store"
        });
        window.open("https://woilo.com/appstore/android.php?source=202", '_blank').focus();
    }

    return (
        <>
            <Carousel>
                <Carousel.Item>
                    <section style={styles.containerStyle}>
                        <Container className='py-5' >
                            <Row className='text-center'>
                                <Col>
                                    <h1 style={styles.headlineStyle}>{t("landingPage.carouselSection.title1")}🙀</h1>
                                    <div style={styles.subtitleStyle}>
                                        {t("landingPage.carouselSection.subtitle1")}
                                    </div>
                                    <div className="mx-auto" style={styles.learMoreStyle} onClick={onDownload}>{t("landingPage.carouselSection.button1")}</div>
                                    <video style={styles.videoStyle} autoPlay loop muted playsInline>
                                        <source src={Video1} type="video/mp4" />
                                    </video>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </Carousel.Item>
                <Carousel.Item>
                    <section style={styles.containerStyle}>
                        <Container className='py-5' >
                            <Row className='text-center'>
                                <Col>
                                    <h1 style={styles.headlineStyle}>{t("landingPage.carouselSection.title2")}🤑</h1>
                                    <div style={styles.subtitleStyle}>
                                        {t("landingPage.carouselSection.subtitle2")}
                                    </div>
                                    <div className="mx-auto" style={styles.learMoreStyle} onClick={onDownload}>{t("landingPage.carouselSection.button2")}</div>
                                    <video style={styles.videoStyle} autoPlay loop muted playsInline>
                                        <source src={Video2} type="video/mp4" />
                                    </video>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </Carousel.Item>
                <Carousel.Item>
                    <section style={styles.containerStyle}>
                        <Container className='py-5' >
                            <Row className='text-center'>
                                <Col>
                                    <h1 style={styles.headlineStyle}>{t("landingPage.carouselSection.title3")}😎 </h1>
                                    <div style={styles.subtitleStyle}>
                                        {t("landingPage.carouselSection.subtitle3")}
                                    </div>
                                    <div className="mx-auto" style={styles.learMoreStyle} onClick={onDownload}>{t("landingPage.carouselSection.button1")}</div>
                                    <video style={styles.videoStyle} autoPlay loop muted playsInline>
                                        <source src={Video3} type="video/mp4" />
                                    </video>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </Carousel.Item>
                <Carousel.Item>
                    <section style={styles.containerStyle}>
                        <Container className='py-5' >
                            <Row className='text-center'>
                                <Col>
                                    <h1 style={styles.headlineStyle}>{t("landingPage.carouselSection.title4")}😱</h1>
                                    <div style={styles.subtitleStyle}>
                                        {t("landingPage.carouselSection.subtitle4")}
                                    </div>
                                    <div className="mx-auto" style={styles.learMoreStyle} onClick={onDownload}>{t("landingPage.carouselSection.button2")}</div>
                                    <video style={styles.videoStyle} autoPlay loop muted playsInline>
                                        <source src={Video4} type="video/mp4" />
                                    </video>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </Carousel.Item>
            </Carousel>
        </>
    )
}

export default CarouselSection;
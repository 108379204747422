import React, { useContext } from 'react';
import { Col, Container, Row, Carousel, Card } from 'react-bootstrap';
import { AppContext } from '../../utils/AppContext';
import { useTranslation } from 'react-i18next';
import ReactGA from "react-ga4";
import UrlHouse from "../../utils/UrlHouse";
import $ from 'jquery';

const ContentCreatorSection = () => {
    const appContext = useContext(AppContext);
    const { t } = useTranslation();

    const desktopStyles = {
        customCardStyle1: {
            background: 'url(/content_creator/hjessica.png)',
            borderRadius: "50px",
            width: "240px",
            height: "272px",
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "relative",
        },

        customCardStyle2: {
            background: 'url(/content_creator/zaen.png)',
            borderRadius: "50px",
            width: "240px",
            height: "272px",
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "relative",
        },

        customCardStyle3: {
            background: 'url(/content_creator/wahyu.png)',
            borderRadius: "50px",
            width: "240px",
            height: "272px",
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "relative",
        },

        customCardStyle4: {
            background: 'url(/content_creator/ayoon.png)',
            borderRadius: "50px",
            width: "240px",
            height: "272px",
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "relative",
        },

        customUsernameStyle: {
            fontFamily: appContext.fontFamily.poppins,
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "170%",
            color: appContext.colors.primaryWhite,
            position: "absolute",
            bottom: "40px",
            marginLeft: "20px"

        },

        customFollowerStyle: {
            fontFamily: appContext.fontFamily.poppins,
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "170%",
            color: appContext.colors.primaryWhite,
            position: "absolute",
            bottom: "15px",
            marginLeft: "20px"
        },

        containerStyle: {
            backgroundColor: appContext.colors.primaryWhite,
        },

        headlineStyle: {
            fontSize: "46px",
            fontWeight: "700",
            fontFamily: appContext.fontFamily.inter,
            color: appContext.colors.primaryBlack,
            lineHeight: "120%"
        },

        subtitleStyle: {
            fontSize: "22px",
            fontWeight: "400",
            fontFamily: appContext.fontFamily.inter,
            color: "#959595",
            marginTop: "20px",
            lineHeight: "120%"
        },

        videoStyle: {
            height: "600px",
            marginTop: "60px"
        },

        learMoreStyle: {
            width: "300px",
            textDecoration: "none",
            textAlign: "center",
            fontSize: "16px",
            fontWeight: "700",
            marginTop: "20px",
            padding: "18px 32px",
            lineHeight: "normal",
            borderRadius: "50px",
            fontFamily: appContext.fontFamily.poppins,
            color: appContext.colors.primaryWhite,
            backgroundColor: appContext.colors.primaryBlue,
        }
    }

    const mobileStyles = {
        customCardStyle1: {
            background: 'url(/content_creator/hjessica.png)',
            borderRadius: "30px",
            width: "141px",
            height: "154px",
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "relative",
        },

        customCardStyle2: {
            background: 'url(/content_creator/zaen.png)',
            borderRadius: "30px",
            width: "141px",
            height: "154px",
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "relative",
        },

        customUsernameStyle: {
            fontFamily: appContext.fontFamily.poppins,
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "170%",
            color: appContext.colors.primaryWhite,
            position: "absolute",
            bottom: "20px",
            marginLeft: "15px"
        },

        customFollowerStyle: {
            fontFamily: appContext.fontFamily.poppins,
            fontSize: "11px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "170%",
            color: appContext.colors.primaryWhite,
            position: "absolute",
            bottom: "0px",
            marginLeft: "15px"
        },

        containerStyle: {
            backgroundColor: appContext.colors.primaryWhite,
        },

        headlineStyle: {
            fontSize: "28px",
            fontWeight: "700",
            fontFamily: appContext.fontFamily.inter,
            color: appContext.colors.primaryBlack,
            lineHeight: "120%"
        },

        subtitleStyle: {
            fontSize: "16px",
            fontWeight: "400",
            fontFamily: appContext.fontFamily.poppins,
            color: "#959595",
            marginTop: "20px",
            lineHeight: "120%"
        },

        videoStyle: {
            height: "600px",
            marginTop: "60px"
        },

        learMoreStyle: {
            width: "300px",
            textDecoration: "none",
            textAlign: "center",
            fontSize: "16px",
            fontWeight: "700",
            marginTop: "20px",
            padding: "18px 32px",
            lineHeight: "normal",
            borderRadius: "50px",
            fontFamily: appContext.fontFamily.poppins,
            color: appContext.colors.primaryWhite,
            backgroundColor: appContext.colors.primaryBlue,
        }
    }

    const styles = appContext.mobileMode ? mobileStyles : desktopStyles

    const onDownload = () => {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        ReactGA.initialize("G-3CZYHKHHW6");
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            ReactGA.event({
                category: "download",
                action: "app_store"
            });
            window.open("https://woilo.com/appstore/ios.php?source=202", '_blank').focus();
            return;
        }
        ReactGA.event({
            category: "download",
            action: "play_store"
        });
        window.open("https://woilo.com/appstore/android.php?source=202", '_blank').focus();
    }

    return appContext.mobileMode ? (
        <>
            <section style={styles.containerStyle} className='py-5'>
                <Container className='py-5' >
                    <Row className='text-center'>
                        <Col>
                            <h1 style={styles.headlineStyle}>{t('landingPage.contentCreatorSection.title')}😊</h1>
                            <div style={styles.subtitleStyle}>
                                {t('landingPage.contentCreatorSection.subtitle')}
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Col className="mx-auto">
                            <div style={styles.customCardStyle1} className="mx-auto">
                                <h2 style={styles.customUsernameStyle}>@hjessicaa_</h2>
                                <p style={styles.customFollowerStyle}>2,9K {t('landingPage.contentCreatorSection.follower')}</p>
                            </div>
                        </Col>
                        <Col>
                            <div style={styles.customCardStyle2} className="mx-auto">
                                <h2 style={styles.customUsernameStyle}>@zaen_c</h2>
                                <p style={styles.customFollowerStyle}>10K {t('landingPage.contentCreatorSection.follower')}</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    ) : (
        <>
            <section style={styles.containerStyle} className='py-5'>
                <Container className='py-5' >
                    <Row className='text-center'>
                        <Col>
                            <h1 style={styles.headlineStyle}>{t('landingPage.contentCreatorSection.title')}😊</h1>
                            <div style={styles.subtitleStyle}>
                                {t('landingPage.contentCreatorSection.subtitle')}
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Col>
                            <div style={styles.customCardStyle1}>
                                <h2 style={styles.customUsernameStyle}>@hjessicaa_</h2>
                                <p style={styles.customFollowerStyle}>2,9K {t('landingPage.contentCreatorSection.follower')}</p>
                            </div>
                        </Col>
                        <Col>
                            <div style={styles.customCardStyle2}>
                                <h2 style={styles.customUsernameStyle}>@zaen_c</h2>
                                <p style={styles.customFollowerStyle}>10K {t('landingPage.contentCreatorSection.follower')}</p>
                            </div>
                        </Col>
                        <Col>
                            <div style={styles.customCardStyle3}>
                                <h2 style={styles.customUsernameStyle}>@wahyu.payul</h2>
                                <p style={styles.customFollowerStyle}>7,23K {t('landingPage.contentCreatorSection.follower')}</p>
                            </div>
                        </Col>
                        <Col>
                            <div style={styles.customCardStyle4}>
                                <h2 style={styles.customUsernameStyle}>@ayoon</h2>
                                <p style={styles.customFollowerStyle}>8,09K {t('landingPage.contentCreatorSection.follower')}</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

        </>
    )
}

export default ContentCreatorSection;
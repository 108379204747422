import React, { useContext } from 'react';
import { Col, Container, Row, Carousel, Card, Image } from 'react-bootstrap';
import { AppContext, AppContextProvider } from '../../utils/AppContext';
import { useTranslation } from 'react-i18next';
import ReactGA from "react-ga4";
import Slider from 'react-infinite-logo-slider';


const Testimoni = () => {
    const appContext = useContext(AppContext);
    const { t } = useTranslation();

    const desktopStyles = {
        customCardStyle: {
            padding: "30px 40px",
            borderRadius: "50px",
            background: appContext.colors.primaryWhite,
            boxShadow: "0px 2px 20px 0px rgba(213, 230, 251, 0.40)",
            margin: "20px"
        },

        customParagraphStyle: {
            fontFamily: appContext.fontFamily.poppins,
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "140%",
            color: appContext.colors.secondaryGrey,
        },

        customNameStyle: {
            fontFamily: appContext.fontFamily.poppins,
            fontSize: "24px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "170%",
            color: appContext.colors.primaryBlack,
        },

        containerStyle: {
            backgroundColor: "#EBF3FE",
        },

        imageStyle: {
            height: "42px",
            width: "auto",
        },

        headlineStyle: {
            fontSize: "46px",
            fontWeight: "700",
            fontFamily: appContext.fontFamily.inter,
            color: appContext.colors.primaryBlack,
            lineHeight: "120%"
        },

        subtitleStyle: {
            fontSize: "22px",
            fontWeight: "400",
            fontFamily: appContext.fontFamily.inter,
            color: "#959595",
            marginTop: "20px",
            lineHeight: "120%"
        },

    }

    const mobileStyles = {
        customCardStyle: {
            padding: "30px 40px",
            borderRadius: "50px",
            background: appContext.colors.primaryWhite,
            boxShadow: "0px 2px 20px 0px rgba(213, 230, 251, 0.40)",
            margin: "20px"
        },

        customParagraphStyle: {
            fontFamily: appContext.fontFamily.poppins,
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "140%",
            color: appContext.colors.secondaryGrey,
        },

        customNameStyle: {
            fontFamily: appContext.fontFamily.poppins,
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "170%",
            color: appContext.colors.primaryBlack,
        },

        containerStyle: {
            backgroundColor: "#EBF3FE",
        },

        imageStyle: {
            height: "38px",
            width: "auto",
        },

        headlineStyle: {
            fontSize: "28px",
            fontWeight: "700",
            fontFamily: appContext.fontFamily.inter,
            color: appContext.colors.primaryBlack,
            lineHeight: "120%"
        },

        subtitleStyle: {
            fontSize: "16px",
            fontWeight: "400",
            fontFamily: appContext.fontFamily.poppins,
            color: "#959595",
            marginTop: "20px",
            lineHeight: "120%"
        },
    }

    const styles = appContext.mobileMode ? mobileStyles : desktopStyles

    return (
        <>
            <section style={styles.containerStyle} className='py-5'>
                <Container className='' >
                    <Row className='text-center'>
                        <Col>
                            <h1 style={styles.headlineStyle}>{t('landingPage.testimoniSection.title')} 🤓</h1>
                            <div style={styles.subtitleStyle}>
                                {t('landingPage.testimoniSection.subtitle')}
                            </div>
                        </Col>
                    </Row>
                </Container>


                <Container fluid>
                    <Slider width="450px" duration={350} pauseOnHover={false} blurBorders={false} blurBoderColor={'#fff'}>
                        <Slider.Slide>
                            <div style={styles.customCardStyle}>
                                <p style={styles.customParagraphStyle}>{t('landingPage.testimoniSection.testi1')}</p>
                                <div style={{ display: 'inline-flex' }} className='vertical-center'>
                                    <Image style={styles.imageStyle} src={"/testimoni/T.png"} />
                                    <h2 style={styles.customNameStyle} className='my-auto ms-2'>Tri Setia</h2>
                                </div>
                            </div>
                        </Slider.Slide>
                        <Slider.Slide>
                            <div style={styles.customCardStyle}>
                                <p style={styles.customParagraphStyle}>{t('landingPage.testimoniSection.testi2')}</p>
                                <div style={{ display: 'inline-flex' }} className='vertical-center'>
                                    <Image style={styles.imageStyle} src={"/testimoni/I.png"} />
                                    <h2 style={styles.customNameStyle} className='my-auto ms-2'>Imam Wahjono</h2>
                                </div>
                            </div>
                        </Slider.Slide>
                        <Slider.Slide>
                            <div style={styles.customCardStyle}>
                                <p style={styles.customParagraphStyle}>{t('landingPage.testimoniSection.testi3')}😊 </p>
                                <div style={{ display: 'inline-flex' }} className='vertical-center'>
                                    <Image style={styles.imageStyle} src={"/testimoni/W.png"} />
                                    <h2 style={styles.customNameStyle} className='my-auto ms-2'>Wiwik</h2>
                                </div>
                            </div>
                        </Slider.Slide>
                        <Slider.Slide>
                            <div style={styles.customCardStyle}>
                                <p style={styles.customParagraphStyle}>{t('landingPage.testimoniSection.testi4')} </p>
                                <div style={{ display: 'inline-flex' }} className='vertical-center'>
                                    <Image style={styles.imageStyle} src={"/testimoni/A.png"} />
                                    <h2 style={styles.customNameStyle} className='my-auto ms-2'>Aslan</h2>
                                </div>
                            </div>
                        </Slider.Slide>
                        <Slider.Slide>
                            <div style={styles.customCardStyle}>
                                <p style={styles.customParagraphStyle}>{t('landingPage.testimoniSection.testi5')}</p>
                                <div style={{ display: 'inline-flex' }} className='vertical-center'>
                                    <Image style={styles.imageStyle} src={"/testimoni/M.png"} />
                                    <h2 style={styles.customNameStyle} className='my-auto ms-2'>Mffly</h2>
                                </div>
                            </div>
                        </Slider.Slide>
                        <Slider.Slide>
                            <div style={styles.customCardStyle}>
                                <p style={styles.customParagraphStyle}>{t('landingPage.testimoniSection.testi6')}</p>
                                <div style={{ display: 'inline-flex' }} className='vertical-center'>
                                    <Image style={styles.imageStyle} src={"/testimoni/A.png"} />
                                    <h2 style={styles.customNameStyle} className='my-auto ms-2'>Anonymous</h2>
                                </div>
                            </div>
                        </Slider.Slide>
                        <Slider.Slide>
                            <div style={styles.customCardStyle}>
                                <p style={styles.customParagraphStyle}>{t('landingPage.testimoniSection.testi7')}</p>
                                <div style={{ display: 'inline-flex' }} className='vertical-center'>
                                    <Image style={styles.imageStyle} src={"/testimoni/J.png"} />
                                    <h2 style={styles.customNameStyle} className='my-auto ms-2'>Joislin Rambu</h2>
                                </div>
                            </div>
                        </Slider.Slide><Slider.Slide>
                            <div style={styles.customCardStyle}>
                                <p style={styles.customParagraphStyle}>{t('landingPage.testimoniSection.testi8')}</p>
                                <div style={{ display: 'inline-flex' }} className='vertical-center'>
                                    <Image style={styles.imageStyle} src={"/testimoni/F.png"} />
                                    <h2 style={styles.customNameStyle} className='my-auto ms-2'>Flowers Girl</h2>
                                </div>
                            </div>
                        </Slider.Slide><Slider.Slide>
                            <div style={styles.customCardStyle}>
                                <p style={styles.customParagraphStyle}>{t('landingPage.testimoniSection.testi9')}</p>
                                <div style={{ display: 'inline-flex' }} className='vertical-center'>
                                    <Image style={styles.imageStyle} src={"/testimoni/A.png"} />
                                    <h2 style={styles.customNameStyle} className='my-auto ms-2'>Anonymous</h2>
                                </div>
                            </div>
                        </Slider.Slide>
                    </Slider>
                </Container>
            </section>

        </>
    )
}

export default Testimoni;
import React, { useEffect, useState , useContext} from "react";
import { useSearchParams , Link} from "react-router-dom";
import { Card, Spinner } from "react-bootstrap";
import UrlHouse from "../../utils/UrlHouse";
import $ from 'jquery';
import { useParams } from "react-router-dom";
import ReactGA from "react-ga4";
import { AppContext } from "../../utils/AppContext";
import Logo from '../../asset/woilo_logo_full.png';
import { Adsense } from '@ctrl/react-adsense';
import '../../AdsenseStylesheet.css';
import FooterSection from "../../sections/FooterSection";

import {Helmet} from "react-helmet";

const NewsContentPage = () => {

    const appContext = useContext(AppContext);

    const [newsData, setNewsData] = useState({
        isLoaded: false,
        sourceName: "",
        author: "",
        title: "",
        description: "",
        content: "",
        url: "",
        imageUrl: "",
        timeStamp: ""
    })

    const [relatedNews, setRelatedNews] = useState([])
    const [relatedNews2, setRelatedNews2] = useState([])
    const [trendingNews, setTrendingNews] = useState([])

    useEffect(() => {
        initNews();
    }, [])

    const initNews = () => {
        $.ajax({
            type: "POST",
            url: UrlHouse.GET_NEWS_CONTENT,
            data: {category: params.category, id: params.news_id},
            dataType: "json",
            success: function (response) {
                if(response.result === 1){
                    setNewsData({
                        isLoaded: true,
                        sourceName: response.news_data.source_name,
                        author: response.news_data.author,
                        title: response.news_data.title,
                        description: response.news_data.description,
                        content: response.news_data.content,
                        url: response.news_data.url,
                        imageUrl: response.news_data.image_url,
                        timeStamp: response.news_data.time_stamp
                    })
                    setLinkPreview({
                        title: "Woilo  •  " + response.news_data.title,
                        description: response.news_data.content,
                        imageUrl: response.news_data.image_url,
                        url: "woilo.com"
                    });

                    let tempArr = [];
                    response.related_news.forEach(newsItem => {
                        let newsTmp = {
                            sourceName: newsItem.source_name,
                            author: newsItem.author,
                            title: newsItem.title,
                            description: newsItem.description,
                            content: newsItem.content,
                            url: newsItem.url,
                            imageUrl: newsItem.image_url,
                            timeStamp: newsItem.time_stamp,
                        }
                        tempArr.push(newsTmp);
                    });
                    let tempArr2 = [];
                    response.related_news_2.forEach(newsItem => {
                        let newsTmp = {
                            sourceName: newsItem.source_name,
                            author: newsItem.author,
                            title: newsItem.title,
                            description: newsItem.description,
                            content: newsItem.content,
                            url: newsItem.url,
                            imageUrl: newsItem.image_url,
                            timeStamp: newsItem.time_stamp,
                        }
                        tempArr2.push(newsTmp);
                    });
                    let tempArr3 = [];
                    response.trending_news.forEach(newsItem => {
                        let newsTmp = {
                            sourceName: newsItem.source_name,
                            author: newsItem.author,
                            title: newsItem.title,
                            description: newsItem.description,
                            content: newsItem.content,
                            url: newsItem.url,
                            imageUrl: newsItem.image_url,
                            timeStamp: newsItem.time_stamp,
                        }
                        tempArr3.push(newsTmp);
                    });
                    setRelatedNews(tempArr);
                    setRelatedNews2(tempArr2);
                    setTrendingNews(tempArr3);
                }else{
                    window.location.replace("https://woilo.com/news");
                }
            },
            error: function(e) {
                window.location.replace("https://woilo.com/news");
            }
        });
    }

    const desktopStyles = {
        mainContainerStyle: {
            flex: "1",
            width: "60%",
            marginLeft: (window.innerWidth/10)+"px",
            padding: "30px",
        },

        secondaryContainerStyle: {
            width: "20%",
            marginRight: (window.innerWidth/10)+"px",
            paddingRight: "30px",
            paddingTop: "30px",
            paddingBottom: "30px"
        },

        headerContainerStyle: {
            flex: "1",
            height: "50px",
            padding: "10px",
            position: "sticky",
            top: "0",
            backgroundColor: appContext.colors.primaryBlue,
        },

        headerLogoStyle: {
            height: "20px",
            marginTop: "5px",
            marginBottom: "5px",
            marginLeft: (window.innerWidth/5)+"px",
            float: "left"
        },

        downloadButtonStyle: {
            marginRight: (window.innerWidth/5)+"px",
            paddingLeft: "5px",
            paddingRight: "5px",
            paddingTop: "3px",
            paddingBottom: "3px",
            fontSize: "15px",
            fontWeight: "500",
            float: "right",
            textDecoration: "none",
            color: appContext.colors.primaryWhite,
            borderStyle: "solid",
            borderRadius: "5px",
            borderColor: appContext.colors.primaryWhite,
            borderWidth: "2px"
        },

        downloadButtonStyleSecondary: {
            width: "100%",
            textAlign: "center",
            marginBottom: "30px",
            padding: "10px",
            fontSize: "15px",
            fontWeight: "500",
            textDecoration: "none",
            color: appContext.colors.primaryWhite,
            borderStyle: "solid",
            borderRadius: "5px",
            backgroundColor: appContext.colors.primaryBlue,
        },

        newsPictureStyle: {
            width: "100%",
            marginTop: "10px",
            marginBottom: "10px",
            borderRadius: "10px"
        },

        labelStyle: {
            color: appContext.colors.primaryGrey,
            fontWeight: "300"
        },

        primaryButtonStyle: {
            width: "100%",
            textAlign: "center",
            marginBottom: "30px",
            marginTop: "20px",
            padding: "10px",
            fontSize: "15px",
            fontWeight: "500",
            textDecoration: "none",
            color: appContext.colors.primaryWhite,
            borderStyle: "solid",
            borderRadius: "5px",
            backgroundColor: appContext.colors.primaryBlue,
        }
    }

    const mobileStyles = {
        mainContainerStyle: {
            width: "100%",
            padding: "30px",
        },

        headerContainerStyle: {
            height: "50px",
            padding: "10px",
            position: "sticky",
            top: "0",
            backgroundColor: appContext.colors.primaryBlue,
        },

        headerLogoStyle: {
            height: "20px",
            marginTop: "5px",
            marginBottom: "5px",
            float: "left"
        },

        downloadButtonStyle: {
            paddingLeft: "5px",
            paddingRight: "5px",
            paddingTop: "3px",
            paddingBottom: "3px",
            fontSize: "15px",
            fontWeight: "500",
            float: "right",
            textDecoration: "none",
            color: appContext.colors.primaryWhite,
            borderStyle: "solid",
            borderRadius: "5px",
            borderColor: appContext.colors.primaryWhite,
            borderWidth: "2px"
        },

        downloadButtonStyleSecondary: {
            width: (window.innerWidth - 60)+"px",
            textAlign: "center",
            marginBottom: "30px",
            padding: "10px",
            fontSize: "15px",
            fontWeight: "500",
            textDecoration: "none",
            color: appContext.colors.primaryWhite,
            borderStyle: "solid",
            borderRadius: "5px",
            backgroundColor: appContext.colors.primaryBlue,
        },

        newsPictureStyle: {
            width: (window.innerWidth - 60)+"px",
            marginTop: "10px",
            marginBottom: "10px",
            borderRadius: "10px"
        },

        labelStyle: {
            color: appContext.colors.primaryGrey
        },

        primaryButtonStyle: {
            width: (window.innerWidth - 60)+"px",
            textAlign: "center",
            marginBottom: "30px",
            marginTop: "20px",
            padding: "10px",
            fontSize: "15px",
            fontWeight: "500",
            textDecoration: "none",
            color: appContext.colors.primaryWhite,
            borderStyle: "solid",
            borderRadius: "5px",
            backgroundColor: appContext.colors.primaryBlue,
        }
    }

    const styles = appContext.mobileMode ? mobileStyles : desktopStyles

    const params = useParams();
    const [linkPreview, setLinkPreview] = useState({
        title: "Berita Woilo - Berita Terkini Dari Woilo",
        description: "Berita Woilo - Berita Terkini Dari Woilo",
        url: "https://woilo.com/news",
        imageUrl: ""
    })

    const onDownload = () => {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        ReactGA.initialize("G-3CZYHKHHW6");
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            ReactGA.event({
                category: "download",
                action: "app_store"
            });
            window.open("https://woilo.com/appstore/ios.php?source=202", '_blank').focus();
            return;
        }
        ReactGA.event({
            category: "download",
            action: "play_store"
        });
        window.open("https://woilo.com/appstore/android.php?source=202", '_blank').focus();
    }

    const openOriginalNews = () => {
        window.open(newsData.url, '_blank').focus();
    }

    function getNewsItem(item) { 
        return (
            <Link style={{textDecoration: "none"}} onClick={() => {
                window.open(item.url,"_self");
            }}>
                <div key={item.url}  style={{
                    width: "100%",
                    marginBottom: "20px",
                    color: appContext.colors.primaryBlack,
                    }}>
                    <h5 style={{fontWeight: "400", marginBottom: "0px",}}>{item.title} </h5>
                    <label style={{color: appContext.colors.primaryGrey}}>{item.timeStamp} • {item.sourceName}</label>
                </div>
            </Link>
        );
    }

    function getNewsItemSecondary(item) { 
        let title = item.title;
        let maxLength = 50;
        if(window.innerWidth < 1000){
            maxLength = 40;
        }
        if(item.title.length > maxLength){
            title = item.title.substring(0, maxLength) + " ...";
        }
        return (
            <Link style={{textDecoration: "none"}} onClick={() => {
                window.open(item.url,"_self");
            }}>
                <div key={item.url}  style={{
                    width: "100%",
                    marginBottom: "20px",
                    color: appContext.colors.primaryBlack,
                    }}>
                    <img style={styles.newsPictureStyle} src={item.imageUrl} alt="content"/>    
                    <h6 style={{fontWeight: "400", marginBottom: "0px"}}>{title} </h6>
                    <label style={{color: appContext.colors.primaryGrey}}>{item.timeStamp} • {item.sourceName}</label>
                </div>
            </Link>
        );
    }

    /*
    <Adsense
                                className='GoogleAdSlot'
                                client='ca-pub-7223798354466955'
                                slot='6504648533'
                                style={{ display: 'block' }}
                                layout="in-article"
                                format="fluid"
                            />
    */

    return(
        <>
            <div style={styles.outerContainerStyle}>
                <>
                    <Helmet>
                        <title>{linkPreview.title}</title>
                        <meta property="og:title" content={linkPreview.title} />
                        <meta property="og:description" content={linkPreview.description} />
                        <meta property="og:image" content={linkPreview.imageUrl} />
                        <meta property="og:url" content={linkPreview.url} />
                        <meta property="og:type" content="website"/>

                        <meta name="twitter:card" content="summary_large_image" />
                        <meta property="twitter:domain" content="woilo.com" />
                        <meta property="twitter:url" content={linkPreview.url} />
                        <meta name="twitter:title" content={linkPreview.title} />
                        <meta name="twitter:description" content={linkPreview.description} />
                        <meta name="twitter:image" content={linkPreview.imageUrl} />
                    </Helmet>
                </>
                {
                    newsData.isLoaded ?
                    appContext.mobileMode?<>
                        <div style={styles.headerContainerStyle}>
                            <Link
                                onClick={() => {
                                    window.open("https://woilo.com","_self");
                                }}>
                                <img alt="woilo" src={Logo}  style={styles.headerLogoStyle} className="d-inline-block"/>
                            </Link>
                            <Link style={styles.downloadButtonStyle} onClick={onDownload}>Unduh Woilo</Link>
                        </div>
                        <div style={styles.mainContainerStyle}>
                            <h2>{newsData.title}</h2>
                            <label>Sumber : {newsData.sourceName}</label><br/>
                            <label style={styles.labelStyle}>{newsData.timeStamp}</label>
                            <img style={styles.newsPictureStyle} src={newsData.imageUrl} alt="content"/>
                            <p style={styles.labelStyle}>{newsData.description}</p>
                            <div id="content-section" dangerouslySetInnerHTML={{__html: newsData.content}}></div>
                            <Adsense
                                className='GoogleAdSlot'
                                client='ca-pub-7223798354466955'
                                slot='6504648533'
                                style={{ display: 'block' }}
                                layout="in-article"
                                format="fluid"
                            />
                            
                            <Link style={{textDecoration: "none"}}>
                                <div style={styles.primaryButtonStyle} onClick={openOriginalNews}>Baca Selengkapnya</div>
                            </Link>
                            <h2 style={{marginTop: "40px"}}>Artikel Terkait</h2>
                            <div style={{width: "100px", height: "6px", borderRadius: "3px", backgroundColor: appContext.colors.primaryBlue, marginBottom: "20px"}}></div>
                            <div>
                                {relatedNews.length > 0 && relatedNews.map((item) => getNewsItem(item))}
                            </div>
                            <Adsense
                                className='GoogleAdSlot'
                                client='ca-pub-7223798354466955'
                                slot='6504648533'
                                style={{ display: 'block' }}
                                layout="in-article"
                                format="fluid"
                            />
                            <h2 style={{marginTop: "40px"}}>Baca Juga</h2>
                            <div style={{width: "100px", height: "6px", borderRadius: "3px", backgroundColor: appContext.colors.primaryBlue, marginBottom: "20px"}}></div>
                            <div>
                                {relatedNews2.length > 0 && relatedNews2.map((item) => getNewsItem(item))}
                            </div>
                        </div>
                        <FooterSection/>
                    </>:
                    <>
                        <div style={styles.headerContainerStyle}>
                            <Link
                                onClick={() => {
                                    window.open("https://woilo.com","_self");
                                }}>
                                <img alt="woilo" src={Logo}  style={styles.headerLogoStyle} className="d-inline-block"/>
                            </Link>
                            <Link style={styles.downloadButtonStyle} onClick={onDownload}>Unduh Woilo</Link>
                        </div>
                        <div style={{display: "flex"}}>
                            <div style={styles.mainContainerStyle}>
                                <h2>{newsData.title}</h2>
                                <label>Sumber : {newsData.sourceName}</label><br/>
                                <label style={styles.labelStyle}>{newsData.timeStamp}</label>
                                <img style={styles.newsPictureStyle} src={newsData.imageUrl} alt="content"/>
                                <p style={styles.labelStyle}>{newsData.description}</p>
                                <div id="content-section" dangerouslySetInnerHTML={{__html: newsData.content}}></div>
                                <Adsense
                                    className='GoogleAdSlot'
                                    client='ca-pub-7223798354466955'
                                    slot='6504648533'
                                    style={{ display: 'block' }}
                                    layout="in-article"
                                    format="fluid"
                                />
                                <Link style={{textDecoration: "none"}}>
                                    <div style={styles.primaryButtonStyle} onClick={openOriginalNews}>Baca Selengkapnya</div>
                                </Link>
                                <h2 style={{marginTop: "40px"}}>Artikel Terkait</h2>
                                <div style={{width: "100px", height: "6px", borderRadius: "3px", backgroundColor: appContext.colors.primaryBlue, marginBottom: "20px"}}></div>
                                <div>
                                    {relatedNews.length > 0 && relatedNews.map((item) => getNewsItem(item))}
                                </div>
                                <Adsense
                                    className='GoogleAdSlot'
                                    client='ca-pub-7223798354466955'
                                    slot='6504648533'
                                    style={{ display: 'block' }}
                                    layout="in-article"
                                    format="fluid"
                                />
                                <h2 style={{marginTop: "40px"}}>Baca Juga</h2>
                                <div style={{width: "100px", height: "6px", borderRadius: "3px", backgroundColor: appContext.colors.primaryBlue, marginBottom: "20px"}}></div>
                                <div>
                                    {relatedNews2.length > 0 && relatedNews2.map((item) => getNewsItem(item))}
                                </div>
                            </div>
                            <div style={styles.secondaryContainerStyle}>
                                <h3>Sedang Hangat</h3>
                                <div style={{width: "100px", height: "6px", borderRadius: "3px", backgroundColor: appContext.colors.primaryBlue, marginBottom: "20px"}}></div>
                                <div>
                                    {trendingNews.length > 0 && trendingNews.map((item) => getNewsItemSecondary(item))}
                                </div>
                            </div>
                        </div>
                        <FooterSection/>
                    </>
                    :
                    <div>
                        <div style={styles.headerContainerStyle}>
                            <img alt="woilo" src={Logo}  style={styles.headerLogoStyle} className="d-inline-block"/>
                            <Link style={styles.downloadButtonStyle} onClick={onDownload}>Unduh Woilo</Link>
                        </div>
                        <div style={{
                            width: "50%",
                            height: "100vh",
                        }}>
                        </div>
                        <FooterSection/>
                    </div>
                    
                }
            </div>
        </>
    )
}

export default NewsContentPage;
export default class UrlHouse {

    static DOMAIN = "https://woilo.com/";
    //static DOMAIN = "https://dev.sestyc.com/";

    baseUrl = this.DOMAIN + "apis/";
    deeplinkUrl = this.DOMAIN + "deeplinks/"
    static DEEPLINK_DOMAIN = this.DOMAIN + "deeplinks/";
    static NEWS_DOMAIN = this.DOMAIN + "news/";

    api_list = {
        "getArticlesScript" : "get_articles_list.php",
        "getArticleDataScript" : "get_article_data.php",
        "registerContentCreator" : "register_content_creator.php",
        "businessInquiry" : "business_inquiry.php"
    }

    deeplink_list = {
        "postDeeplinkScript" : "post.php",
        "userDeeplinkScript" : "user.php",
        "appDeepLinkScript" : "app/app.php",
        "nftDeepLinkScript" : "nft/nft.php"
    }

    static POST_DEEP_LINK = this.DEEPLINK_DOMAIN + "post.php";
    static USER_DEEP_LINK = this.DEEPLINK_DOMAIN + "user.php";
    static NFT_DEEP_LINK = this.DEEPLINK_DOMAIN + "nft.php";

    static CONTACT_US = this.DOMAIN + "apis/business_inquiry.php";

    static GET_NEWS_CONTENT = this.NEWS_DOMAIN + "get_news_content.php";
    static GET_NEWS_MAIN = this.NEWS_DOMAIN + "get_news_main.php";
    static GET_NEWS_CATEGORY = this.NEWS_DOMAIN + "get_news_category.php";

    static GET_ARTICLES_LIST = "getArticlesListScript";
    static GET_ARTICLE_DATA = "getArticleDataScript";
    static REGISTER_CONTENT_CREATOR = "registerContentCreator";
    static BUSINESS_INQUIRY = "businessInquiry";
    static APP_DEEP_LINK = "appDeepLinkScript";

    getApiUrl(key){
        return this.baseUrl + this.api_list[key];
    }
}
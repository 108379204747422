
import './App.css';
import { useState, useContext, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { AppContext } from './utils/AppContext';

import LandingPage from './pages/LandingPage';
import MainPage from './pages/MainPage';
import TermOfServiePage from './pages/TermOfServicePage';
import ContactUsPage from './pages/ContactUsPage';
import DownloadPage from './pages/DownloadPage';

import UserDeepLink from './pages/deeplinks/User';
import PostDeeplink from './pages/deeplinks/Post';

import NewsContentPage from './pages/news/NewsContentPage';
import NewsPage from './pages/news/NewsPage';
import NewsCategoryPage from './pages/news/NewsCategoryPage';

import DataPolicy from './sections/tos/DataPolicy';
import UsageGuidelines from './sections/tos/UsageGuidelines';
import PrivateAccount from './sections/tos/PrivateAccount';
import VerifiedAccount from './sections/tos/VerifiedAccount';
import WoiloCoin from './sections/tos/WoiloCoin';
import WoiloWallet from './sections/tos/WoiloWallet';
import PopularityEvent from './sections/tos/PopularityEvent';
import NftTerms from './sections/tos/NftTerms';
import WoiloBoost from './pages/WoiloBoost';
import FootballTerms from './sections/tos/FootballTerms';


function App() {

  const [ tosActivePage, setTosActivePage ] = useState(0);
  const appContext = useContext(AppContext);

  const onTosPageChange = (page) => {
    setTosActivePage(page);
  }

  useEffect(() => {
    //document.title = "Woilo - Find New Friends";
    let isDarkMode = localStorage.getItem('isDarkMode');
    if( isDarkMode === null){
      localStorage.setItem('isDarkMode',false)
    }else{
      appContext.setIsDarkMode(JSON.parse(isDarkMode));
    }

    let path = window.location.pathname;

    if(path === '/term.html' || path === '/en/term.html'){
      window.location = ('/tos');
    }
  }, [])

  return (
    <Routes>
      <Route path="/" element={<LandingPage/>}>
        <Route index element={<MainPage/>}/>
        <Route path="tos" element={<TermOfServiePage activePage={tosActivePage} onPageChange={onTosPageChange}/>}>
          <Route index element={<DataPolicy styles={appContext.tosStyles}/>}/>
          <Route path="usage_guidelines" element={<UsageGuidelines styles={appContext.tosStyles}/>}/>
          <Route path="private_account" element={<PrivateAccount styles={appContext.tosStyles}/>}/>
          <Route path="verified_account" element={<VerifiedAccount styles={appContext.tosStyles}/>}/>
          <Route path="woilo_coin" element={<WoiloCoin styles={appContext.tosStyles}/>}/>
          <Route path="woilo_wallet" element={<WoiloWallet styles={appContext.tosStyles}/>}/>
          <Route path="popularity_event" element={<PopularityEvent styles={appContext.tosStyles}/>}/>
          <Route path="nft_terms" element={<NftTerms styles={appContext.tosStyles}/>}/>
          <Route path="football_terms" element={<FootballTerms styles={appContext.tosStyles}/>}/>
        </Route>
        <Route path="contact" element={<ContactUsPage/>}/>
        <Route path="download" element={<DownloadPage/>}/>
        <Route path="nft/:nft_id" element={<MainPage/>} />
      </Route>
      <Route path="/woilo-boost" element={<WoiloBoost/>}/>
      <Route path="u/:user_name" element={<UserDeepLink/>} />
      <Route path="p/:post_id" element={<PostDeeplink/>} />
      <Route path="news" element={<NewsPage/>} />
      <Route path="news/:category" element={<NewsCategoryPage/>} />
      <Route path="news/:category/:news_id" element={<NewsContentPage/>} />
    </Routes>
  );
}

export default App;

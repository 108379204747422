import React, { useContext } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { AppContext, AppContextProvider } from '../../utils/AppContext';
import { useTranslation } from 'react-i18next';
import Slider from 'react-infinite-logo-slider';

import partner1 from '../../asset/new_asset/logo_partner/cnbc.png';
import partner2 from '../../asset/new_asset/logo_partner/cnn.png';
import partner3 from '../../asset/new_asset/logo_partner/google.png';
import partner4 from '../../asset/new_asset/logo_partner/herald.png';
import partner5 from '../../asset/new_asset/logo_partner/jawapos.png';
import partner6 from '../../asset/new_asset/logo_partner/lyrid.png';
import partner7 from '../../asset/new_asset/logo_partner/uiux.png';
import partner8 from '../../asset/new_asset/logo_partner/jjc.png';
import partner9 from '../../asset/new_asset/logo_partner/zmat.png';
import partner10 from '../../asset/new_asset/logo_partner/kabarpas.png';


const RatingSection = () => {
    const appContext = useContext(AppContext);
    const { t } = useTranslation();

    const desktopStyles = {

        statsContainerStyle: {
            textAlign: "center",
            padding: "40px 20px",
            width: "70%",
            marginLeft: (window.innerWidth * 15 / 100) + "px",
            marginRIght: (window.innerWidth * 15 / 100) + "px"
        },

        statsNumbersStyle: {
            fontSize: "46px",
            fontWeight: "700",
            fontFamily: appContext.fontFamily.inter,
            lineHeight: "120%",
            color: appContext.colors.primaryBlack,
        },

        statsIconStyle: {
            marginLeft: "10px",
            width: "15px",
            height: "15px"
        },

        statsTitleStyle: {
            fontSize: "28px",
            fontFamily: appContext.fontFamily.inter,
            fontWeight: "500",
            lineHeight: "120%",
            color: appContext.colors.secondaryGrey,
        },

        seperator: {
            borderLeft: "1px solid #CACACA",
            height: "100px",
        },

        partnersAsset: {
            filter: "grayscale(100%)",
            height: "auto",
            maxWidth: "150px",
        }
    }

    const mobileStyles = {

        statsContainerStyle: {
            textAlign: "center",
            padding: "0px",
            margin: "auto",
        },

        statsNumbersStyle: {
            fontSize: "18px",
            fontWeight: "700",
            fontFamily: appContext.fontFamily.inter,
            lineHeight: "120%",
            color: appContext.colors.primaryBlack,
        },

        statsIconStyle: {
            marginLeft: "5px",
            width: "0px",
        },

        statsTitleStyle: {
            fontSize: "14px",
            fontFamily: appContext.fontFamily.inter,
            fontWeight: "500",
            lineHeight: "120%",
            color: appContext.colors.secondaryGrey,
        },

        seperator: {
            borderLeft: "1px solid #CACACA",
            height: "auto",
        },

        partnersAsset: {
            filter: "grayscale(100%)",
            height: "auto",
            maxWidth: "130px",
        }
    }

    const styles = appContext.mobileMode ? mobileStyles : desktopStyles

    return appContext.mobileMode ? (
        <>
            <Row style={styles.statsContainerStyle} id="rating_section" className="rating_section">
                <Col className="d-flex justify-content-center"></Col>
                <Col className="justify-content-center my-auto mx-2">
                    <h5 style={styles.statsTitleStyle}>Rating</h5>
                    <h1 style={styles.statsNumbersStyle}>
                        4,5
                        <i style={{ ...styles.statsIconStyle, color: "#FFB573" }} className="fa fa-star" aria-hidden="true"></i>
                    </h1>
                </Col>
                <Col className="d-flex justify-content-center">
                    <div style={styles.seperator}></div>
                </Col>
                <Col className="justify-content-center my-auto mx-2">
                    <h5 style={styles.statsTitleStyle}>
                        {t('landingPage.headlineSection.review')}
                    </h5>
                    <h1 style={styles.statsNumbersStyle}>
                        30K+
                        <i style={{ ...styles.statsIconStyle, color: "#2E80EB" }} className="fa fa-file-text" aria-hidden="true"></i>
                    </h1>
                </Col>
                <Col className="d-flex justify-content-center">
                    <div style={styles.seperator}></div>
                </Col>
                <Col className="justify-content-center my-auto mx-2">
                    <h5 style={styles.statsTitleStyle}>Download</h5>
                    <h1 style={styles.statsNumbersStyle}>
                        2{t('landingPage.headlineSection.million')}
                        <i style={{ ...styles.statsIconStyle, color: "#2E80EB" }} className="fa fa-users" aria-hidden="true"></i>
                    </h1>
                </Col>
                <Col className="d-flex justify-content-center"></Col>
            </Row>


            <Container fluid className='my-5'>
                <Slider width="200px" duration={40} pauseOnHover={true} blurBorders={true} blurBoderColor={'#fff'}>
                    <Slider.Slide>
                        <a href='woilo.https://www.cnbcindonesia.com/tech/20210721115611-37-262419/woilo-aplikasi-pesaing-instagram-made-in-surabaya' target="_blank"><Image style={styles.partnersAsset} src={partner1} /></a>
                    </Slider.Slide>
                    <Slider.Slide>
                        <a href='https://www.cnnindonesia.com/teknologi/20220831161720-190-841497/mengenal-lebih-dekat-aplikasi-woilo-dan-jatuh-bangun-trio-pendirinya' target='_blank'><Image style={styles.partnersAsset} src={partner2} /></a>
                    </Slider.Slide>
                    <Slider.Slide>
                        <a href='https://google.com/' target='_blank'><Image style={styles.partnersAsset} src={partner3} /> </a>
                    </Slider.Slide>
                    <Slider.Slide>
                        <a href='https://herald.id/' target='_blank'> <Image style={styles.partnersAsset} src={partner4} /></a>
                    </Slider.Slide>
                    <Slider.Slide>
                        <a href='https://www.jawapos.com/surabaya-raya/01249305/lima-pemuda-asli-suroboyo-ciptakan-aplikasi-media-sosial-sestyc' target='_blank'><Image style={styles.partnersAsset} src={partner5} /></a>
                    </Slider.Slide>
                    <Slider.Slide>
                        <a href='https://www.lyrid.io/' target='_blank'><Image style={styles.partnersAsset} src={partner6} /></a>
                    </Slider.Slide>
                    <Slider.Slide>
                        <a href='https://www.uiuxindo.id/' target='_blank'><Image className='p-3' style={styles.partnersAsset} src={partner7} /></a>
                    </Slider.Slide>
                    <Slider.Slide>
                        <a href='https://www.instagram.com/jjc_law/' target='_blank'><Image className='p-3' style={styles.partnersAsset} src={partner8} /></a>
                    </Slider.Slide>
                    <Slider.Slide>
                        <a href='https://www.zmaticoo.com/' target='_blank'><Image className='p-3' style={styles.partnersAsset} src={partner9} /></a>
                    </Slider.Slide>
                    <Slider.Slide>
                        <a href='https://www.kabarpas.com/' target='_blank'><Image className='p-3' style={styles.partnersAsset} src={partner10} /></a>
                    </Slider.Slide>
                </Slider>
            </Container>
            <b></b>
        </>
    ) : (
        <>
            <Row style={styles.statsContainerStyle} id="rating_section" className="rating_section">
                <Col className="justify-content-center my-auto">
                    <h5 style={styles.statsTitleStyle}>Rating</h5>
                    <h1 style={styles.statsNumbersStyle}>
                        4,5
                        <i style={{ ...styles.statsIconStyle, color: "#FFB573" }} className="fa fa-star" aria-hidden="true"></i>
                    </h1>
                </Col>
                <Col className="d-flex justify-content-center">
                    <div style={styles.seperator}></div>
                </Col>
                <Col className="justify-content-center my-auto">
                    <h5 style={styles.statsTitleStyle}>
                        {t('landingPage.headlineSection.review')}
                    </h5>
                    <h1 style={styles.statsNumbersStyle}>
                        30K+
                        <i style={{ ...styles.statsIconStyle, color: "#2E80EB" }} className="fa fa-file-text" aria-hidden="true"></i>
                    </h1>
                </Col>
                <Col className="d-flex justify-content-center">
                    <div style={styles.seperator}></div>
                </Col>
                <Col className="justify-content-center my-auto">
                    <h5 style={styles.statsTitleStyle}>Download</h5>
                    <h1 style={styles.statsNumbersStyle}>
                        2{t('landingPage.headlineSection.million')}
                        <i style={{ ...styles.statsIconStyle, color: "#2E80EB" }} className="fa fa-users" aria-hidden="true"></i>
                    </h1>
                </Col>
            </Row>


            <Container fluid className='my-5'>
                <Slider width="200px" duration={40} pauseOnHover={false} blurBorders={false} blurBoderColor={'#fff'}>
                    <Slider.Slide>
                        <a href='woilo.https://www.cnbcindonesia.com/tech/20210721115611-37-262419/woilo-aplikasi-pesaing-instagram-made-in-surabaya' target="_blank"><Image style={styles.partnersAsset} src={partner1} /></a>
                    </Slider.Slide>
                    <Slider.Slide>
                        <a href='https://www.cnnindonesia.com/teknologi/20220831161720-190-841497/mengenal-lebih-dekat-aplikasi-woilo-dan-jatuh-bangun-trio-pendirinya' target='_blank'><Image style={styles.partnersAsset} src={partner2} /></a>
                    </Slider.Slide>
                    <Slider.Slide>
                        <a href='https://google.com/' target='_blank'><Image style={styles.partnersAsset} src={partner3} /> </a>
                    </Slider.Slide>
                    <Slider.Slide>
                        <a href='https://herald.id/' target='_blank'> <Image style={styles.partnersAsset} src={partner4} /></a>
                    </Slider.Slide>
                    <Slider.Slide>
                        <a href='https://www.jawapos.com/surabaya-raya/01249305/lima-pemuda-asli-suroboyo-ciptakan-aplikasi-media-sosial-sestyc' target='_blank'><Image style={styles.partnersAsset} src={partner5} /></a>
                    </Slider.Slide>
                    <Slider.Slide>
                        <a href='https://www.lyrid.io/' target='_blank'><Image style={styles.partnersAsset} src={partner6} /></a>
                    </Slider.Slide>
                    <Slider.Slide>
                        <a href='https://www.uiuxindo.id/' target='_blank'><Image className='p-3' style={styles.partnersAsset} src={partner7} /></a>
                    </Slider.Slide>
                    <Slider.Slide>
                        <a href='https://www.instagram.com/jjc_law/' target='_blank'><Image className='p-3' style={styles.partnersAsset} src={partner8} /></a>
                    </Slider.Slide>
                    <Slider.Slide>
                        <a href='https://www.zmaticoo.com/' target='_blank'><Image className='p-0' style={styles.partnersAsset} src={partner9} /></a>
                    </Slider.Slide>
                </Slider>
            </Container>
        </>
    )
}

export default RatingSection;
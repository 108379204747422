import React, { useEffect, useState , useContext} from "react";
import { useSearchParams , Link} from "react-router-dom";
import { Card, Spinner } from "react-bootstrap";
import UrlHouse from "../../utils/UrlHouse";
import $ from 'jquery';
import { useParams } from "react-router-dom";
import ReactGA from "react-ga4";
import { AppContext } from "../../utils/AppContext";
import Logo from '../../asset/woilo_logo_full.png';

import TrendingIcon from '../../asset/news_icon_trending.png';
import BusinessIcon from '../../asset/news_icon_business.png';
import EntertainmentIcon from '../../asset/news_icon_entertainment.png';
import SportsIcon from '../../asset/news_icon_sports.png';
import TechnologyIcon from '../../asset/news_icon_technology.png';
import ScienceIcon from '../../asset/news_icon_science.png';
import HealthIcon from '../../asset/news_icon_health.png';
import { Adsense } from '@ctrl/react-adsense';
import '../../AdsenseStylesheet.css';
import FooterSection from "../../sections/FooterSection";

import {Helmet} from "react-helmet";

const NewsPage = () => {

    const appContext = useContext(AppContext);

    const [isLoaded, setIsloaded] = useState(false);

    const [trendingNews, setTrendingNews] = useState([])
    const [trendingNews2, setTrendingNews2] = useState([])
    const [trendingNews3, setTrendingNews3] = useState([])
    const [trendingNews4, setTrendingNews4] = useState([])

    const [businessNews, setBusinessNews] = useState([])
    const [entertainmentNews, setEntertainmentNews] = useState([])
    const [sportsNews, setSportsNews] = useState([])
    const [technologyNews, setTechnologyNews] = useState([])
    const [scienceNews, setScienceNews] = useState([])
    const [healthNews, setHealthNews] = useState([])

    useEffect(() => {
        initNews();
    }, [])

    const initNews = () => {
        $.ajax({
            type: "POST",
            url: UrlHouse.GET_NEWS_MAIN,
            data: {},
            dataType: "json",
            success: function (response) {
                if(response.result === 1){
                    setIsloaded(true);
                    let tempArr = [];
                    response.trending_news.forEach(newsItem => {
                        let newsTmp = {
                            sourceName: newsItem.source_name,
                            author: newsItem.author,
                            title: newsItem.title,
                            description: newsItem.description,
                            content: newsItem.content,
                            url: newsItem.url,
                            imageUrl: newsItem.image_url,
                            timeStamp: newsItem.time_stamp,
                        }
                        tempArr.push(newsTmp);
                    });
                    setTrendingNews(tempArr);

                    let tempArr2 = [];
                    response.trending_news_2.forEach(newsItem => {
                        let newsTmp = {
                            sourceName: newsItem.source_name,
                            author: newsItem.author,
                            title: newsItem.title,
                            description: newsItem.description,
                            content: newsItem.content,
                            url: newsItem.url,
                            imageUrl: newsItem.image_url,
                            timeStamp: newsItem.time_stamp,
                        }
                        tempArr2.push(newsTmp);
                    });
                    setTrendingNews2(tempArr2);

                    let tempArr3 = [];
                    response.trending_news_3.forEach(newsItem => {
                        let newsTmp = {
                            sourceName: newsItem.source_name,
                            author: newsItem.author,
                            title: newsItem.title,
                            description: newsItem.description,
                            content: newsItem.content,
                            url: newsItem.url,
                            imageUrl: newsItem.image_url,
                            timeStamp: newsItem.time_stamp,
                        }
                        tempArr3.push(newsTmp);
                    });
                    setTrendingNews3(tempArr3);

                    let tempArr4 = [];
                    response.trending_news_4.forEach(newsItem => {
                        let newsTmp = {
                            sourceName: newsItem.source_name,
                            author: newsItem.author,
                            title: newsItem.title,
                            description: newsItem.description,
                            content: newsItem.content,
                            url: newsItem.url,
                            imageUrl: newsItem.image_url,
                            timeStamp: newsItem.time_stamp,
                        }
                        tempArr4.push(newsTmp);
                    });
                    setTrendingNews4(tempArr4);


                    let tempArr5 = [];
                    response.business_news.forEach(newsItem => {
                        let newsTmp = {
                            sourceName: newsItem.source_name,
                            author: newsItem.author,
                            title: newsItem.title,
                            description: newsItem.description,
                            content: newsItem.content,
                            url: newsItem.url,
                            imageUrl: newsItem.image_url,
                            timeStamp: newsItem.time_stamp,
                        }
                        tempArr5.push(newsTmp);
                    });
                    setBusinessNews(tempArr5);

                    let tempArr6 = [];
                    response.entertainment_news.forEach(newsItem => {
                        let newsTmp = {
                            sourceName: newsItem.source_name,
                            author: newsItem.author,
                            title: newsItem.title,
                            description: newsItem.description,
                            content: newsItem.content,
                            url: newsItem.url,
                            imageUrl: newsItem.image_url,
                            timeStamp: newsItem.time_stamp,
                        }
                        tempArr6.push(newsTmp);
                    });
                    setEntertainmentNews(tempArr6);

                    let tempArr7 = [];
                    response.sports_news.forEach(newsItem => {
                        let newsTmp = {
                            sourceName: newsItem.source_name,
                            author: newsItem.author,
                            title: newsItem.title,
                            description: newsItem.description,
                            content: newsItem.content,
                            url: newsItem.url,
                            imageUrl: newsItem.image_url,
                            timeStamp: newsItem.time_stamp,
                        }
                        tempArr7.push(newsTmp);
                    });
                    setSportsNews(tempArr7);

                    let tempArr8 = [];
                    response.technology_news.forEach(newsItem => {
                        let newsTmp = {
                            sourceName: newsItem.source_name,
                            author: newsItem.author,
                            title: newsItem.title,
                            description: newsItem.description,
                            content: newsItem.content,
                            url: newsItem.url,
                            imageUrl: newsItem.image_url,
                            timeStamp: newsItem.time_stamp,
                        }
                        tempArr8.push(newsTmp);
                    });
                    setTechnologyNews(tempArr8);

                    let tempArr9 = [];
                    response.science_news.forEach(newsItem => {
                        let newsTmp = {
                            sourceName: newsItem.source_name,
                            author: newsItem.author,
                            title: newsItem.title,
                            description: newsItem.description,
                            content: newsItem.content,
                            url: newsItem.url,
                            imageUrl: newsItem.image_url,
                            timeStamp: newsItem.time_stamp,
                        }
                        tempArr9.push(newsTmp);
                    });
                    setScienceNews(tempArr9);

                    let tempArr10 = [];
                    response.health_news.forEach(newsItem => {
                        let newsTmp = {
                            sourceName: newsItem.source_name,
                            author: newsItem.author,
                            title: newsItem.title,
                            description: newsItem.description,
                            content: newsItem.content,
                            url: newsItem.url,
                            imageUrl: newsItem.image_url,
                            timeStamp: newsItem.time_stamp,
                        }
                        tempArr10.push(newsTmp);
                    });
                    setHealthNews(tempArr10);
                }else{
                    window.location.replace("https://woilo.com");
                }
            },
            error: function(e) {
                window.location.replace("https://woilo.com");
            }
        });
    }

    const desktopStyles = {
        mainContainerStyle: {
            width: "80%",
            marginLeft: (window.innerWidth/10)+"px",
            marginRight: (window.innerWidth/10)+"px",
            padding: "30px",
        },

        headerContainerStyle: {
            height: "50px",
            padding: "10px",
            position: "sticky",
            top: "0",
            backgroundColor: appContext.colors.primaryBlue,
        },

        headerLogoStyle: {
            height: "20px",
            marginTop: "5px",
            marginBottom: "5px",
            marginLeft: (window.innerWidth/10 + 30)+"px",
            float: "left"
        },

        downloadButtonStyle: {
            marginRight: (window.innerWidth/10 + 30)+"px",
            paddingLeft: "5px",
            paddingRight: "5px",
            paddingTop: "3px",
            paddingBottom: "3px",
            fontSize: "15px",
            fontWeight: "500",
            float: "right",
            textDecoration: "none",
            color: appContext.colors.primaryWhite,
            borderStyle: "solid",
            borderRadius: "5px",
            borderColor: appContext.colors.primaryWhite,
            borderWidth: "2px"
        },

        downloadButtonStyleSecondary: {
            width: "100%",
            textAlign: "center",
            marginBottom: "30px",
            padding: "10px",
            fontSize: "15px",
            fontWeight: "500",
            textDecoration: "none",
            color: appContext.colors.primaryWhite,
            borderStyle: "solid",
            borderRadius: "5px",
            backgroundColor: appContext.colors.primaryBlue,
        },

        trendingNewsContainerStyle: {
            width: "100%",
            display: "flex"
        }
    }

    const mobileStyles = {
        mainContainerStyle: {
            width: "100%",
            padding: "30px",
        },

        headerContainerStyle: {
            height: "50px",
            padding: "10px",
            position: "sticky",
            top: "0",
            backgroundColor: appContext.colors.primaryBlue,
        },

        headerLogoStyle: {
            height: "20px",
            marginTop: "5px",
            marginBottom: "5px",
            float: "left"
        },

        downloadButtonStyle: {
            paddingLeft: "5px",
            paddingRight: "5px",
            paddingTop: "3px",
            paddingBottom: "3px",
            fontSize: "15px",
            fontWeight: "500",
            float: "right",
            textDecoration: "none",
            color: appContext.colors.primaryWhite,
            borderStyle: "solid",
            borderRadius: "5px",
            borderColor: appContext.colors.primaryWhite,
            borderWidth: "2px"
        },

        downloadButtonStyleSecondary: {
            width: (window.innerWidth - 60)+"px",
            textAlign: "center",
            marginBottom: "30px",
            padding: "10px",
            fontSize: "15px",
            fontWeight: "500",
            textDecoration: "none",
            color: appContext.colors.primaryWhite,
            borderStyle: "solid",
            borderRadius: "5px",
            backgroundColor: appContext.colors.primaryBlue,
        },

        newsPictureStyle: {
            width: (window.innerWidth - 60)+"px",
            marginTop: "10px",
            marginBottom: "10px",
            borderRadius: "10px"
        },

        labelStyle: {
            color: appContext.colors.primaryGrey
        },

        primaryButtonStyle: {
            width: (window.innerWidth - 60)+"px",
            textAlign: "center",
            marginBottom: "30px",
            padding: "10px",
            fontSize: "15px",
            fontWeight: "500",
            textDecoration: "none",
            color: appContext.colors.primaryWhite,
            borderStyle: "solid",
            borderRadius: "5px",
            backgroundColor: appContext.colors.primaryBlue,
        },

        trendingNewsContainerStyle: {
            width: "100%"
        }
    }

    const styles = appContext.mobileMode ? mobileStyles : desktopStyles

    const params = useParams();
    const [linkPreview, setLinkPreview] = useState({
        title: "Berita Woilo  •  Berita Terkini Dari Woilo",
        description: "Berita Woilo  •  Berita Terkini Dari Woilo",
        url: "https://woilo.com/news",
        imageUrl: ""
    })

    const onDownload = () => {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        ReactGA.initialize("G-3CZYHKHHW6");
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            ReactGA.event({
                category: "download",
                action: "app_store"
            });
            window.open("https://woilo.com/appstore/ios.php?source=202", '_blank').focus();
            return;
        }
        ReactGA.event({
            category: "download",
            action: "play_store"
        });
        window.open("https://woilo.com/appstore/android.php?source=202", '_blank').focus();
    }

    function getTrendingNewsItem(item) { 
        return (
            appContext.mobileMode?
            <Link style={{
                textDecoration: "none",
                width: "100%"}} onClick={() => {
                    window.open(item.url,"_self");
                }}>
                <div key={item.url}  style={{
                    marginBottom: "20px",
                    width: "100%",
                    color: appContext.colors.primaryBlack,
                    }}>
                    <img src={item.imageUrl} style={{
                        width: "100%",
                        marginTop: "10px",
                        marginBottom: "10px",
                        borderRadius: "5px"
                    }} alt="news_thumb"></img>
                    <h6 style={{fontWeight: "400"}}>{item.title} </h6>
                    <label style={{color: appContext.colors.primaryGrey}}>{item.timeStamp} • {item.sourceName}</label>
                </div>
            </Link>
            :
            <Link style={{
                textDecoration: "none",
                padding: "10px",
                flex: "1",
                display: "flex",
                flexDirection: "column"}} onClick={() => {
                    window.open(item.url,"_self");
                }}>
                <div key={item.url}  style={{
                    marginBottom: "20px",
                    color: appContext.colors.primaryBlack,
                    }}>
                    <img src={item.imageUrl} style={{
                        width: "100%",
                        marginTop: "10px",
                        marginBottom: "10px",
                        borderRadius: "5px"
                    }} alt="news_thumb"></img>
                    <h6 style={{fontWeight: "400"}}>{item.title} </h6>
                    <label style={{color: appContext.colors.primaryGrey}}>{item.sourceName}</label>
                </div>
            </Link>
        );
    }

    function getNewsItem(item) { 
        return (
            <Link style={{textDecoration: "none", marginLeft: appContext.mobileMode?"0px":"10px", display: "flex"}} onClick={() => {
                window.open(item.url,"_self");
            }}>
                <div key={item.url}  style={{
                    width: "100%",
                    marginTop: "10px",
                    marginBottom: "10px",
                    color: appContext.colors.primaryBlack,
                    }}>
                    <h6 style={{fontWeight: "400", marginBottom: "0px",}}>{item.title} </h6>
                    <label style={{color: appContext.colors.primaryGrey}}>{item.timeStamp} • {item.sourceName}</label>
                </div>
            </Link>
        );
    }

    const [hover1, setHover1] = useState(false)
    const [hover2, setHover2] = useState(false)
    const [hover3, setHover3] = useState(false)
    const [hover4, setHover4] = useState(false)
    const [hover5, setHover5] = useState(false)
    const [hover6, setHover6] = useState(false)
    const [hover7, setHover7] = useState(false)

    function getCategoryContainer(){
        return (
            <div style={{
                marginBottom: "10px",
                paddingLeft: appContext.mobileMode?"0px":"10px",
                paddingRight: appContext.mobileMode?"0px":"10px"}}>
                <Link style={{
                    textDecoration: "none",
                    height: "40.4px",
                    display: "inline-block",
                    paddingLeft: "25px",
                    paddingRight: "23px",
                    paddingTop: "4px",
                    paddingBottom: "3px",
                    border: "solid",
                    borderRadius: "20px",
                    marginRight: "10px",
                    borderWidth: "2px",
                    marginBottom: "10px",
                    borderColor: appContext.colors.primaryBlue,
                    backgroundColor: appContext.colors.primaryBlue
                    }}>
                    <Link style={{fontWeight: "500", color: appContext.colors.primaryWhite, textDecoration: "none"}}>Beranda</Link>
                </Link>
                <Link style={{
                    textDecoration: "none",
                    color: hover1?appContext.colors.primaryBlue:appContext.colors.primaryGrey,
                    display: "inline-block",
                    paddingLeft: "20px",
                    paddingRight: "25px",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    border: "solid",
                    borderRadius: "20px",
                    marginRight: "10px",
                    marginBottom: "10px",
                    borderWidth: "2px",
                    backgroundColor: hover1?appContext.colors.primaryBlue:appContext.colors.primaryWhite,
                    }}
                    onClick={() => {
                        window.open("https://woilo.com/news/trending","_self");
                    }}
                    onMouseEnter={() => {setHover1(true)}}
                    onMouseLeave={() => {setHover1(false)}}>
                    <img src={TrendingIcon} style={{width: "20px", height: "20px", marginBottom: "4px"}} alt="icon"></img>
                    <Link style={{fontWeight: "500", textDecoration: "none", color: hover1?appContext.colors.primaryWhite:appContext.colors.primaryBlack}}>Trending</Link>
                </Link>
                <Link style={{
                    textDecoration: "none",
                    color: hover2?appContext.colors.primaryBlue:appContext.colors.primaryGrey,
                    display: "inline-block",
                    paddingLeft: "20px",
                    paddingRight: "25px",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    border: "solid",
                    borderRadius: "20px",
                    marginRight: "10px",
                    marginBottom: "10px",
                    borderWidth: "2px",
                    backgroundColor: hover2?appContext.colors.primaryBlue:appContext.colors.primaryWhite,
                    }}
                    onClick={() => {
                        window.open("https://woilo.com/news/business","_self");
                    }}
                    onMouseEnter={() => {setHover2(true)}}
                    onMouseLeave={() => {setHover2(false)}}>
                    <img src={BusinessIcon} style={{width: "20px", height: "20px", marginBottom: "4px", marginRight: "5px"}} alt="icon"></img>
                    <Link style={{fontWeight: "500", textDecoration: "none", color: hover2?appContext.colors.primaryWhite:appContext.colors.primaryBlack}}>Bisnis</Link>
                </Link>
                <Link style={{
                    textDecoration: "none",
                    color: hover3?appContext.colors.primaryBlue:appContext.colors.primaryGrey,
                    display: "inline-block",
                    paddingLeft: "20px",
                    paddingRight: "25px",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    border: "solid",
                    borderRadius: "20px",
                    marginRight: "10px",
                    marginBottom: "10px",
                    borderWidth: "2px",
                    backgroundColor: hover3?appContext.colors.primaryBlue:appContext.colors.primaryWhite,
                    }}
                    onClick={() => {
                        window.open("https://woilo.com/news/entertainment","_self");
                    }}
                    onMouseEnter={() => {setHover3(true)}}
                    onMouseLeave={() => {setHover3(false)}}>
                    <img src={EntertainmentIcon} style={{width: "20px", height: "20px", marginBottom: "4px", marginRight: "5px"}} alt="icon"></img>
                    <Link style={{fontWeight: "500", textDecoration: "none", color: hover3?appContext.colors.primaryWhite:appContext.colors.primaryBlack}}>Hiburan</Link>
                </Link>
                <Link style={{
                    textDecoration: "none",
                    color: hover4?appContext.colors.primaryBlue:appContext.colors.primaryGrey,
                    display: "inline-block",
                    paddingLeft: "20px",
                    paddingRight: "25px",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    border: "solid",
                    borderRadius: "20px",
                    marginRight: "10px",
                    marginBottom: "10px",
                    borderWidth: "2px",
                    backgroundColor: hover4?appContext.colors.primaryBlue:appContext.colors.primaryWhite,
                    }}
                    onClick={() => {
                        window.open("https://woilo.com/news/sports","_self");
                    }}
                    onMouseEnter={() => {setHover4(true)}}
                    onMouseLeave={() => {setHover4(false)}}>
                    <img src={SportsIcon} style={{width: "20px", height: "20px", marginBottom: "4px", marginRight: "5px"}} alt="icon"></img>
                    <Link style={{fontWeight: "500", textDecoration: "none", color: hover4?appContext.colors.primaryWhite:appContext.colors.primaryBlack}}>Sport</Link>
                </Link>
                <Link style={{
                    textDecoration: "none",
                    color: hover5?appContext.colors.primaryBlue:appContext.colors.primaryGrey,
                    display: "inline-block",
                    paddingLeft: "20px",
                    paddingRight: "25px",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    border: "solid",
                    borderRadius: "20px",
                    marginRight: "10px",
                    marginBottom: "10px",
                    borderWidth: "2px",
                    backgroundColor: hover5?appContext.colors.primaryBlue:appContext.colors.primaryWhite,
                    }}
                    onClick={() => {
                        window.open("https://woilo.com/news/technology","_self");
                    }}
                    onMouseEnter={() => {setHover5(true)}}
                    onMouseLeave={() => {setHover5(false)}}>
                    <img src={TechnologyIcon} style={{width: "20px", height: "20px", marginBottom: "4px", marginRight: "5px"}} alt="icon"></img>
                    <Link style={{fontWeight: "500", textDecoration: "none", color: hover5?appContext.colors.primaryWhite:appContext.colors.primaryBlack}}>Teknologi</Link>
                </Link>
                <Link style={{
                    textDecoration: "none",
                    color: hover6?appContext.colors.primaryBlue:appContext.colors.primaryGrey,
                    display: "inline-block",
                    paddingLeft: "20px",
                    paddingRight: "25px",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    border: "solid",
                    borderRadius: "20px",
                    marginRight: "10px",
                    marginBottom: "10px",
                    borderWidth: "2px",
                    backgroundColor: hover6?appContext.colors.primaryBlue:appContext.colors.primaryWhite,
                    }}
                    onClick={() => {
                        window.open("https://woilo.com/news/health","_self");
                    }}
                    onMouseEnter={() => {setHover6(true)}}
                    onMouseLeave={() => {setHover6(false)}}>
                    <img src={HealthIcon} style={{width: "20px", height: "20px", marginBottom: "4px", marginRight: "5px"}} alt="icon"></img>
                    <Link style={{fontWeight: "500", textDecoration: "none", color: hover6?appContext.colors.primaryWhite:appContext.colors.primaryBlack}}>Kesehatan</Link>
                </Link>
                <Link style={{
                    textDecoration: "none",
                    color: hover7?appContext.colors.primaryBlue:appContext.colors.primaryGrey,
                    display: "inline-block",
                    paddingLeft: "20px",
                    paddingRight: "25px",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    border: "solid",
                    borderRadius: "20px",
                    marginRight: "10px",
                    marginBottom: "10px",
                    borderWidth: "2px",
                    backgroundColor: hover7?appContext.colors.primaryBlue:appContext.colors.primaryWhite,
                    }}
                    onClick={() => {
                        window.open("https://woilo.com/news/science","_self");
                    }}
                    onMouseEnter={() => {setHover7(true)}}
                    onMouseLeave={() => {setHover7(false)}}>
                    <img src={ScienceIcon} style={{width: "20px", height: "20px", marginBottom: "4px", marginRight: "5px"}} alt="icon"></img>
                    <Link style={{fontWeight: "500", textDecoration: "none", color: hover7?appContext.colors.primaryWhite:appContext.colors.primaryBlack}}>Sains</Link>
                </Link>
            </div>
        );
    }

    return(
        <>
            <div style={styles.outerContainerStyle}>
                <>
                    <Helmet>
                        <title>{linkPreview.title}</title>
                        <meta property="og:title" content={linkPreview.title} />
                        <meta property="og:description" content={linkPreview.description} />
                        <meta property="og:image" content={linkPreview.imageUrl} />
                        <meta property="og:url" content={linkPreview.url} />
                        <meta property="og:type" content="website"/>

                        <meta name="twitter:card" content="summary_large_image" />
                        <meta property="twitter:domain" content="woilo.com" />
                        <meta property="twitter:url" content={linkPreview.url} />
                        <meta name="twitter:title" content={linkPreview.title} />
                        <meta name="twitter:description" content={linkPreview.description} />
                        <meta name="twitter:image" content={linkPreview.imageUrl} />
                    </Helmet>
                </>
                {
                    isLoaded ?
                    <>
                        <div style={styles.headerContainerStyle}>
                            <Link
                                onClick={() => {
                                    window.open("https://woilo.com","_self");
                                }}>
                                <img alt="woilo" src={Logo}  style={styles.headerLogoStyle} className="d-inline-block"/>
                            </Link>
                            <Link style={styles.downloadButtonStyle} onClick={onDownload}>Unduh Woilo</Link>
                        </div>
                        <div style={styles.mainContainerStyle}>
                            {getCategoryContainer()}
                            <h2 style={{marginTop: "0px", marginLeft: appContext.mobileMode?"0px":"10px"}}>Berita Utama</h2>
                            <div style={{width: "100px", height: "6px", marginLeft: appContext.mobileMode?"0px":"10px", borderRadius: "3px", backgroundColor: appContext.colors.primaryBlue, marginBottom: "20px"}}></div>
                            <div style={styles.trendingNewsContainerStyle}>
                                {trendingNews.length > 0 && trendingNews.map((item) => getTrendingNewsItem(item))}
                                {
                                    appContext.mobileMode?
                                    <>
                                        <Adsense
                                            className='GoogleAdSlot'
                                            client='ca-pub-7223798354466955'
                                            slot='6504648533'
                                            style={{ display: 'block' }}
                                            layout="in-article"
                                            format="fluid"
                                        />
                                    </>:<></>
                                }
                            </div>
                            <div>
                                <h2 style={{marginTop: appContext.mobileMode?"30px":"0px", marginLeft: appContext.mobileMode?"0px":"10px"}}>Sedang Hangat</h2>
                                <div style={{width: "100px", height: "6px", marginLeft: appContext.mobileMode?"0px":"10px", borderRadius: "3px", backgroundColor: appContext.colors.primaryBlue, marginBottom: "20px"}}></div>
                                <div style={styles.trendingNewsContainerStyle}>
                                    {trendingNews2.length > 0 && trendingNews2.map((item) => getTrendingNewsItem(item))}
                                    {
                                        appContext.mobileMode?
                                        <>
                                            <Adsense
                                                className='GoogleAdSlot'
                                                client='ca-pub-7223798354466955'
                                                slot='6504648533'
                                                style={{ display: 'block' }}
                                                layout="in-article"
                                                format="fluid"
                                            />
                                        </>:<></>
                                    }
                                </div>
                            </div>

                            {
                                !appContext.mobileMode?
                                <>
                                    <Adsense
                                        className='GoogleAdSlot'
                                        client='ca-pub-7223798354466955'
                                        slot='6504648533'
                                        style={{ display: 'block' }}
                                        layout="in-article"
                                        format="fluid"
                                    />
                                </>:<></>
                            }

                            <div style={appContext.mobileMode?{width: "100%"}:{width: "100%", display: "flex"}}>
                                <div style={{flex: "1"}}>
                                    <h2 style={{marginTop: appContext.mobileMode?"30px":"0px", marginLeft: appContext.mobileMode?"0px":"10px"}}>Rekomendasi</h2>
                                    <div style={{width: "100px", height: "6px", marginLeft: appContext.mobileMode?"0px":"10px", borderRadius: "3px", backgroundColor: appContext.colors.primaryBlue, marginBottom: "20px"}}></div>
                                    <div>
                                        {trendingNews3.length > 0 && trendingNews3.map((item) => getNewsItem(item))}
                                        {
                                            appContext.mobileMode?
                                            <>
                                                <Adsense
                                                    className='GoogleAdSlot'
                                                    client='ca-pub-7223798354466955'
                                                    slot='6504648533'
                                                    style={{ display: 'block' }}
                                                    layout="in-article"
                                                    format="fluid"
                                                />
                                            </>:<></>
                                        }
                                    </div>
                                </div>
                                <div style={{flex: "1"}}>
                                    <h2 style={{marginTop: appContext.mobileMode?"30px":"0px", marginLeft: appContext.mobileMode?"0px":"10px"}}>Baca Juga</h2>
                                    <div style={{width: "100px", height: "6px", marginLeft: appContext.mobileMode?"0px":"10px", borderRadius: "3px", backgroundColor: appContext.colors.primaryBlue, marginBottom: "20px"}}></div>
                                    <div>
                                        {trendingNews4.length > 0 && trendingNews4.map((item) => getNewsItem(item))}
                                        {
                                            appContext.mobileMode?
                                            <>
                                                <Adsense
                                                    className='GoogleAdSlot'
                                                    client='ca-pub-7223798354466955'
                                                    slot='6504648533'
                                                    style={{ display: 'block' }}
                                                    layout="in-article"
                                                    format="fluid"
                                                />
                                            </>:<></>
                                        }
                                    </div>
                                </div>
                            </div>

                            <h2 style={{marginTop: "0px", marginLeft: appContext.mobileMode?"0px":"10px"}}>Kabar Bisnis</h2>
                            <div style={{width: "100px", height: "6px", marginLeft: appContext.mobileMode?"0px":"10px", borderRadius: "3px", backgroundColor: appContext.colors.primaryBlue, marginBottom: "20px"}}></div>
                            <div style={styles.trendingNewsContainerStyle}>
                                {businessNews.length > 0 && businessNews.map((item) => getTrendingNewsItem(item))}
                                {
                                    appContext.mobileMode?
                                    <>
                                        <Adsense
                                            className='GoogleAdSlot'
                                            client='ca-pub-7223798354466955'
                                            slot='6504648533'
                                            style={{ display: 'block' }}
                                            layout="in-article"
                                            format="fluid"
                                        />
                                    </>:<></>
                                }
                            </div>

                            <h2 style={{marginTop: "0px", marginLeft: appContext.mobileMode?"0px":"10px"}}>Dunia Hiburan</h2>
                            <div style={{width: "100px", height: "6px", marginLeft: appContext.mobileMode?"0px":"10px", borderRadius: "3px", backgroundColor: appContext.colors.primaryBlue, marginBottom: "20px"}}></div>
                            <div style={styles.trendingNewsContainerStyle}>
                                {entertainmentNews.length > 0 && entertainmentNews.map((item) => getTrendingNewsItem(item))}
                                {
                                    appContext.mobileMode?
                                    <>
                                        <Adsense
                                            className='GoogleAdSlot'
                                            client='ca-pub-7223798354466955'
                                            slot='6504648533'
                                            style={{ display: 'block' }}
                                            layout="in-article"
                                            format="fluid"
                                        />
                                    </>:<></>
                                }
                            </div>
                            {
                                !appContext.mobileMode?
                                <>
                                    <Adsense
                                        className='GoogleAdSlot'
                                        client='ca-pub-7223798354466955'
                                        slot='6504648533'
                                        style={{ display: 'block' }}
                                        layout="in-article"
                                        format="fluid"
                                    />
                                </>:<></>
                            }
                            <h2 style={{marginTop: "0px", marginLeft: appContext.mobileMode?"0px":"10px"}}>Sport Terkini</h2>
                            <div style={{width: "100px", height: "6px", marginLeft: appContext.mobileMode?"0px":"10px", borderRadius: "3px", backgroundColor: appContext.colors.primaryBlue, marginBottom: "20px"}}></div>
                            <div style={styles.trendingNewsContainerStyle}>
                                {sportsNews.length > 0 && sportsNews.map((item) => getTrendingNewsItem(item))}
                                {
                                    appContext.mobileMode?
                                    <>
                                        <Adsense
                                            className='GoogleAdSlot'
                                            client='ca-pub-7223798354466955'
                                            slot='6504648533'
                                            style={{ display: 'block' }}
                                            layout="in-article"
                                            format="fluid"
                                        />
                                    </>:<></>
                                }
                            </div>

                            <h2 style={{marginTop: "0px", marginLeft: appContext.mobileMode?"0px":"10px"}}>Melek Teknologi</h2>
                            <div style={{width: "100px", height: "6px", marginLeft: appContext.mobileMode?"0px":"10px", borderRadius: "3px", backgroundColor: appContext.colors.primaryBlue, marginBottom: "20px"}}></div>
                            <div style={styles.trendingNewsContainerStyle}>
                                {technologyNews.length > 0 && technologyNews.map((item) => getTrendingNewsItem(item))}
                                {
                                    appContext.mobileMode?
                                    <>
                                        <Adsense
                                            className='GoogleAdSlot'
                                            client='ca-pub-7223798354466955'
                                            slot='6504648533'
                                            style={{ display: 'block' }}
                                            layout="in-article"
                                            format="fluid"
                                        />
                                    </>:<></>
                                }
                            </div>

                            <h2 style={{marginTop: "0px", marginLeft: appContext.mobileMode?"0px":"10px"}}>Belajar Sains</h2>
                            <div style={{width: "100px", height: "6px", marginLeft: appContext.mobileMode?"0px":"10px", borderRadius: "3px", backgroundColor: appContext.colors.primaryBlue, marginBottom: "20px"}}></div>
                            <div style={styles.trendingNewsContainerStyle}>
                                {scienceNews.length > 0 && scienceNews.map((item) => getTrendingNewsItem(item))}
                                {
                                    appContext.mobileMode?
                                    <>
                                        <Adsense
                                            className='GoogleAdSlot'
                                            client='ca-pub-7223798354466955'
                                            slot='6504648533'
                                            style={{ display: 'block' }}
                                            layout="in-article"
                                            format="fluid"
                                        />
                                    </>:<></>
                                }
                            </div>
                            {
                                !appContext.mobileMode?
                                <>
                                    <Adsense
                                        className='GoogleAdSlot'
                                        client='ca-pub-7223798354466955'
                                        slot='6504648533'
                                        style={{ display: 'block' }}
                                        layout="in-article"
                                        format="fluid"
                                    />
                                </>:<></>
                            }
                            <h2 style={{marginTop: "0px", marginLeft: appContext.mobileMode?"0px":"10px"}}>Hidup Sehat</h2>
                            <div style={{width: "100px", height: "6px", marginLeft: appContext.mobileMode?"0px":"10px", borderRadius: "3px", backgroundColor: appContext.colors.primaryBlue, marginBottom: "20px"}}></div>
                            <div style={styles.trendingNewsContainerStyle}>
                                {healthNews.length > 0 && healthNews.map((item) => getTrendingNewsItem(item))}
                                {
                                    appContext.mobileMode?
                                    <>
                                        <Adsense
                                            className='GoogleAdSlot'
                                            client='ca-pub-7223798354466955'
                                            slot='6504648533'
                                            style={{ display: 'block' }}
                                            layout="in-article"
                                            format="fluid"
                                        />
                                    </>:<></>
                                }
                            </div>
                        </div>
                        <FooterSection/>
                    </>
                    :
                    <div>
                        <div style={styles.headerContainerStyle}>
                            <img alt="woilo" src={Logo}  style={styles.headerLogoStyle} className="d-inline-block"/>
                            <Link style={styles.downloadButtonStyle} onClick={onDownload}>Unduh Woilo</Link>
                        </div>
                        <div style={{
                            width: "50%",
                            height: "100vh",
                        }}>
                        </div>
                        <FooterSection/>
                    </div>
                    
                }
            </div>
        </>
    )
}

export default NewsPage;
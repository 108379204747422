import Section1 from "../sections/main_page/Section1";
import Section2 from "../sections/main_page/Section2";
import Section3 from "../sections/main_page/Section3";
import Section4 from "../sections/main_page/Section4";
import Section5 from "../sections/main_page_2/Section5";
import RatingSection from '../sections/main_page/RatingSection';
import React, { useEffect, useState , useContext} from "react";
import { Redirect } from "react-router-dom";
import ReactGA from "react-ga4";



const DownloadPage = () => {

    useEffect(() => {
        onDownload();
    }, [])

    const onDownload = async()  => {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        ReactGA.initialize("G-3CZYHKHHW6");
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            console.log("open app store")
            ReactGA.event({
                category: "download",
                action: "app_store"
            });
            window.open("https://woilo.com/appstore/ios.php?source=202").focus();
            //return <Redirect to="https://woilo.com/appstore/ios.php?source=202" />
        }
        ReactGA.event({
            category: "download",
            action: "play_store"
        });
        console.log("open play store")
        window.open("https://woilo.com/appstore/android.php?source=202").focus();
        //return <Redirect to="https://woilo.com/appstore/android.php?source=202" />
    }

    return(
        <>
            <Section1/>
            <RatingSection/>
            <Section2/>
            <Section3/>
            <Section4/>
            <Section5/>
        </>
    )
}

export default DownloadPage;
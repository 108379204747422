import Section1 from "../sections/main_page/Section1";
import Section2 from "../sections/main_page/Section2";
import Section3 from "../sections/main_page/Section3";
import Section4 from "../sections/main_page/Section4";
import ContentCreatorSection from "../sections/main_page/ContentCreatorSection";
import RatingSection from '../sections/main_page/RatingSection';
import SectionPromote from "../sections/main_page/SectionPromote";
import WalletSection from "../sections/main_page/WalletSection";
import CarouselSection from "../sections/main_page/CarouselSection";
import Testimoni from "../sections/main_page/Testimoni";
import FeedbackSection from "../sections/main_page/Feedback";

const MainPage = () => {
    //<SectionPromote /> exlucded temporary
    return (
        <>
            
            <Section1 />
            <RatingSection />
            
            <Section2 />
            <Section3 />
            <Section4 />
            <CarouselSection />
            <ContentCreatorSection />
            <Testimoni />
            <FeedbackSection />
        </>
    )
}

export default MainPage;
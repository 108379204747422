import React, { useContext, useState, useEffect } from "react";
import { Navbar, Nav, Button, Row, Col, Modal } from 'react-bootstrap';
import Logo from '../asset/logo_woilo_full.svg';
import INFlag from '../asset/in_flag.png';
import USFlag from '../asset/us_flag.png';
import NavbarToggle from '../asset/navbar_toggle.svg';
import { Link } from "react-router-dom";
import $ from 'jquery';
import { AppContext } from "../utils/AppContext";
import { useTranslation } from 'react-i18next';
import ReactGA from "react-ga4";

const NavigationBar = () => {
    const [activePage, setActivePage] = useState('/');
    const [modalShow, setModalShow] = useState(false);
    const [englishMode, setEnglishMode] = useState(false);
    const appContext = useContext(AppContext);
    const { t, i18n } = useTranslation();

    const desktopStyles = {

        navbarContainerStyle: {
            padding: "10px 30px",
            backgroundColor: appContext.colors.primaryWhite
        },

        navbarToggleStyle: {
            borderColor: "transparent",
            borderRadius: "50%",
            padding: "0"
        },

        logoStyle: {
            width: "75px",
            margin: "10px"
        },

        navItemStyle: {
            marginRight: "30px",
            fontWeight: "500",
            borderBottom: "2px solid transparent",
            fontFamily: appContext.fontFamily.inter,
            fontSize: "16px",
            color: appContext.colors.primary
        },

        navItemStyleDownload: {
            fontWeight: "500",
            borderRadius: "10px",
            marginTop: "5px",
            marginBottom: "5px",
            borderBottom: "2px solid transparent",
            backgroundColor: appContext.colors.primaryBlue
        },

        navItemStyleDownloadDesktop: {
            fontWeight: "500",
            borderRadius: "50px",
            borderBottom: "2px solid transparent",
            backgroundColor: appContext.colors.primaryBlue
        },

        downloadButtonStyle: {
            color: appContext.colors.primaryBlue,
            fontWeight: "500",
            borderRadius: "50px",
            backgroundColor: appContext.colors.primaryWhite
        },

        navItemLinkStyle: {
            color: "#5C5C5C",
        },

        navItemLinkStyleDownload: {
            padding: "10px",
            color: appContext.colors.primaryWhite,
        },

        navItemLinkStyleDownloadDesktop: {
            color: appContext.colors.primaryWhite,
        },

        navLanguangeSwitchIconStyle: {
            width: "30px",
        }
    }

    const mobileStyles = {
        navbarStyle: {

        },

        navbarContainerStyle: {
            padding: "0 20px",
            backgroundColor: appContext.colors.primaryWhite
        },

        navbarToggleStyle: {
            borderColor: "transparent",
            borderRadius: "50%",
            padding: "0"
        },

        logoStyle: {
            width: "75px",
            margin: "10px"
        },

        navItemStyle: {
            marginLeft: "15px",
            marginRight: "15px",
            fontWeight: "500",
            borderBottom: "2px solid transparent",
        },

        navItemStyleDownload: {
            fontWeight: "500",
            marginLeft: "15px",
            marginRight: "15px",
            borderRadius: "10px",
            marginTop: "5px",
            marginBottom: "5px",
            borderBottom: "2px solid transparent",
            backgroundColor: appContext.colors.primaryBlue
        },

        navItemStyleDownloadDesktop: {
            fontWeight: "500",
            borderRadius: "10px",
            borderBottom: "2px solid transparent",
            backgroundColor: appContext.colors.primaryBlue
        },

        downloadButtonStyle: {
            color: appContext.colors.primaryBlue,
            fontWeight: "500",
            borderRadius: "50px",
            backgroundColor: appContext.colors.primaryWhite
        },

        navItemLinkStyle: {
            color: appContext.colors.primaryBlack,
        },

        navItemLinkStyleDownload: {
            padding: "10px",
            color: appContext.colors.primaryWhite,
        },

        navItemLinkStyleDownloadDesktop: {
            color: appContext.colors.primaryWhite,
        },

        navLanguangeSwitchIconStyle: {
            width: "30px",
            marginRight: "10px"
        }
    }

    const styles = appContext.mobileMode ? mobileStyles : desktopStyles

    const onNavItemHover = (e) => {
        $(`#${e.target.id}`).css('border-bottom', '2px solid ' + appContext.colors.primaryBlue)
    }

    const handlePageWoiloBoost = () => {
        window.open("https://boost.woilo.com");
    }

    const onNavItemStopHover = (e) => {
        $(`#${e.target.id}`).css('border-bottom', '2px solid transparent')
    }

    const handlePageChange = (e) => {
        $('.nav-link').removeClass('active');
        $(`#${e.target.id}`).addClass('active');
    }

    const handleLanguageChange = () => {
        let lang = englishMode ? 'en' : 'id';
        i18n.changeLanguage(lang);
        setEnglishMode(!englishMode);
    }

    const onDownload = () => {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        ReactGA.initialize("G-3CZYHKHHW6");
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            ReactGA.event({
                category: "download",
                action: "app_store"
            });
            window.open("https://woilo.com/appstore/ios.php?source=202", '_blank').focus();
            return;
        }
        ReactGA.event({
            category: "download",
            action: "play_store"
        });
        window.open("https://woilo.com/appstore/android.php?source=202", '_blank').focus();
    }

    return appContext.mobileMode ? (
        <>
            <Navbar style={styles.navbarContainerStyle} expand="lg">
                <Navbar.Brand style={styles.navbarStyle}>
                    <img alt="woilo" src={Logo} height="75" style={{ marginRight: "10px" }} className="d-inline-block" />
                </Navbar.Brand>
                <div>
                    <Navbar.Toggle style={styles.navbarToggleStyle} aria-controls="basic-navbar-nav"><img src={NavbarToggle} alt="navbarToggle" /></Navbar.Toggle>
                </div>
                <Navbar.Collapse id="basic-navbar-nav" >
                    <Nav className="justify-content-center" activeKey="/">
                        <Nav.Item id="nav-home" onMouseEnter={onNavItemHover} onMouseLeave={onNavItemStopHover} style={styles.navItemStyle}>
                            <Link style={styles.navItemLinkStyle} id="nav-home" to="/" href="/" name="Home" className="nav-link active" onClick={handlePageChange}>{t('navigationBar.home')}</Link>
                        </Nav.Item>
                        {/*<Nav.Item id="nav-about" onMouseEnter={onNavItemHover} onMouseLeave={onNavItemStopHover} style={styles.navItemStyle}>
                            <Link style={styles.navItemLinkStyle} id="nav-about" to="about" href="about" name="About" className="nav-link" onClick={handlePageChange}>{t('navigationBar.about')}</Link>
                        </Nav.Item>
                        {/* <Nav.Item id="nav-blog" onMouseEnter={onNavItemHover} onMouseLeave={onNavItemStopHover} style={styles.navItemStyle}>
                            <Link style={styles.navItemLinkStyle} id="nav-blog" to="blog" href="blog" name="Blog" className="nav-link" onClick={handlePageChange}>Blog</Link>
                        </Nav.Item> */}
                        {/* <Nav.Item id="nav-career" onMouseEnter={onNavItemHover} onMouseLeave={onNavItemStopHover} style={styles.navItemStyle}>
                            <Link style={styles.navItemLinkStyle} id="nav-career" to="career" href="career" name="Career" className="nav-link" onClick={handlePageChange}>Karir</Link>
                        </Nav.Item> */}
                        <Nav.Item id="nav-tos" onMouseEnter={onNavItemHover} onMouseLeave={onNavItemStopHover} style={styles.navItemStyle}>
                            <Link style={styles.navItemLinkStyle} id="nav-tos" to="tos" href="tos" name="ToS" className="nav-link" onClick={handlePageChange}>{t('navigationBar.tos')}</Link>
                        </Nav.Item>
                        {/*<Nav.Item id="nav-help" onMouseEnter={onNavItemHover} onMouseLeave={onNavItemStopHover} style={styles.navItemStyle}>
                            <Link style={styles.navItemLinkStyle} id="nav-help" to="help" href="help" name="Help" className="nav-link" onClick={handlePageChange}>{t('navigationBar.help')}</Link>
                        </Nav.Item>*/}
                        <Nav.Item id="nav-contact" onMouseEnter={onNavItemHover} onMouseLeave={onNavItemStopHover} style={styles.navItemStyle}>
                            <Link style={styles.navItemLinkStyle} id="nav-contact" to="contact" href="contact" name="Contact Us" className="nav-link" onClick={handlePageChange}>{t('navigationBar.contact')}</Link>
                        </Nav.Item>
                        <Nav.Item id="nav-contact" onMouseEnter={onNavItemHover} onMouseLeave={onNavItemStopHover} style={styles.navItemStyle}>
                            <Link style={styles.navItemLinkStyle} id="nav-news" to="news" href="news" name="News" className="nav-link" onClick={handlePageChange}>{t('navigationBar.news')}</Link>
                        </Nav.Item>
                        {/* <Nav.Item id="nav-contact" onMouseEnter={onNavItemHover} onMouseLeave={onNavItemStopHover} style={styles.navItemStyle}>
                            <Link style={styles.navItemLinkStyle} id="nav-boost" name="boost" className="nav-link" onClick={handlePageWoiloBoost}>Woilo Boost <sup style={{ color: "red" }}>Baru!</sup></Link>
                        </Nav.Item> */}
                        <Nav.Item id="nav-help" onMouseEnter={onNavItemHover} onMouseLeave={onNavItemStopHover} style={styles.navItemStyleDownload}>
                            <Link style={styles.navItemLinkStyleDownload} id="nav-help" className="nav-link" onClick={onDownload}>{t('navigationBar.freeDownload')}</Link>
                        </Nav.Item>
                        <Nav.Item id="nav-contact" onMouseEnter={onNavItemHover} onMouseLeave={onNavItemStopHover} style={styles.navItemStyle}>
                            <Link style={styles.navItemLinkStyle} id="nav-language" className="nav-link" onClick={handleLanguageChange}><img style={styles.navLanguangeSwitchIconStyle} src={t('navigationBar.home') === "Beranda" ? USFlag : INFlag} alt="languageSwitch"></img>{t('navigationBar.switchLanguage')}</Link>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </>
    ) : (
        <>
            <Navbar style={styles.navbarContainerStyle} expand="lg">
                <Navbar.Brand style={styles.navbarStyle}>
                    <img alt="woilo" src={Logo} height="75" style={{ marginRight: "10px" }} className="d-inline-block" />
                </Navbar.Brand>
                <div>
                    <Navbar.Toggle style={styles.navbarToggleStyle} aria-controls="basic-navbar-nav"><img src={NavbarToggle} alt="navbarToggle" /></Navbar.Toggle>
                </div>
                <Navbar.Collapse id="basic-navbar-nav" >
                    <Nav className="justify-content-center" activeKey="/">
                        <Nav.Item id="nav-home" onMouseEnter={onNavItemHover} onMouseLeave={onNavItemStopHover} style={styles.navItemStyle}>
                            <Link style={styles.navItemLinkStyle} id="nav-home" to="/" href="/" name="Home" className="nav-link active" onClick={handlePageChange}>{t('navigationBar.home')}</Link>
                        </Nav.Item>
                        <Nav.Item id="nav-tos" onMouseEnter={onNavItemHover} onMouseLeave={onNavItemStopHover} style={styles.navItemStyle}>
                            <Link style={styles.navItemLinkStyle} id="nav-tos" to="tos" href="tos" name="ToS" className="nav-link" onClick={handlePageChange}>{t('navigationBar.tos')}</Link>
                        </Nav.Item>
                        {/*<Nav.Item id="nav-help" onMouseEnter={onNavItemHover} onMouseLeave={onNavItemStopHover} style={styles.navItemStyle}>
                            <Link style={styles.navItemLinkStyle} id="nav-help" to="help" href="help" name="Help" className="nav-link" onClick={handlePageChange}>{t('navigationBar.help')}</Link>
                        </Nav.Item>*/}
                        <Nav.Item id="nav-contact" onMouseEnter={onNavItemHover} onMouseLeave={onNavItemStopHover} style={styles.navItemStyle}>
                            <Link style={styles.navItemLinkStyle} id="nav-contact" to="contact" href="contact" name="Contact Us" className="nav-link" onClick={handlePageChange}>{t('navigationBar.contact')}</Link>
                        </Nav.Item>
                        <Nav.Item id="nav-contact" onMouseEnter={onNavItemHover} onMouseLeave={onNavItemStopHover} style={styles.navItemStyle}>
                            <Link style={styles.navItemLinkStyle} id="nav-news" to="news" href="news" name="News" className="nav-link" onClick={handlePageChange}>{t('navigationBar.news')}</Link>
                        </Nav.Item>
                        {/* <Nav.Item id="nav-contact" onMouseEnter={onNavItemHover} onMouseLeave={onNavItemStopHover} style={styles.navItemStyle}>
                            <Link style={styles.navItemLinkStyle} id="nav-woiloboost" name="boost" className="nav-link" onClick={handlePageWoiloBoost}>Woilo Boost <sup style={{ color: "red" }}>Baru!</sup></Link>
                        </Nav.Item> */}
                    </Nav>
                    <Nav className="ms-auto">
                        <Nav.Link onClick={onDownload} className="align-self-center me-4" style={styles.navItemStyleDownloadDesktop}>
                            <Link style={styles.navItemLinkStyleDownloadDesktop} className="nav-link" onClick={onDownload}>{t('navigationBar.freeDownload')}</Link>
                        </Nav.Link>
                        <Nav.Link onClick={handleLanguageChange} className="align-self-center me-4">
                            <img style={styles.navLanguangeSwitchIconStyle} src={t('navigationBar.home') === "Beranda" ? USFlag : INFlag} alt="languageSwitch"></img>
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </>
    )
}

export default NavigationBar;
import React from "react";
import { useTranslation } from "react-i18next";

const WoiloWallet = (props) => {
    const styles = props.styles;
    const { t } = useTranslation();

    return(
        <>
            <p style={styles.tosContentLastChangedStyle}>{t('termsOfServicePage.lastChanged')}</p>
            <p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.woiloWallet.description')}</p>
            <h4 style={styles.tosContentSubtitleStyle}>{t('termsOfServicePage.woiloWallet.subtitle')}</h4>
            <p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.woiloWallet.subtitleDescription')}</p>
            <ul >
                <li style={styles.tosListBulletStyle}><p style={styles.tosContentDescriptionStyle}> {t('termsOfServicePage.woiloWallet.termList1.li1')}</p></li>
                <li style={styles.tosListBulletStyle}><p style={styles.tosContentDescriptionStyle}> {t('termsOfServicePage.woiloWallet.termList1.li2')}</p></li>
                <li style={styles.tosListBulletStyle}><p style={styles.tosContentDescriptionStyle}> {t('termsOfServicePage.woiloWallet.termList1.li3')}</p></li>
            </ul>
            <p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.woiloWallet.warning')}</p>
            <h4 style={styles.tosContentSubtitleStyle}>{t('termsOfServicePage.woiloWallet.subtitle2')}</h4>
            <ul >
                <li style={styles.tosListBulletStyle}><p style={styles.tosContentDescriptionStyle}> {t('termsOfServicePage.woiloWallet.termList2.li1')}</p></li>
                <li style={styles.tosListBulletStyle}><p style={styles.tosContentDescriptionStyle}> {t('termsOfServicePage.woiloWallet.termList2.li2')}</p></li>
            </ul>
            <h3 style={styles.tosContentTitleStyle}>{t('termsOfServicePage.changesDisclaimer.title')}</h3>
            <p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.changesDisclaimer.content.p1')}</p>
            <p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.changesDisclaimer.content.p2')}</p>
            <p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.changesDisclaimer.content.p3')}</p>
        </>
    )
}

export default WoiloWallet
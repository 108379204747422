import React, { useContext } from 'react';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import { AppContext } from '../../utils/AppContext';
import { useTranslation } from 'react-i18next';
import ReactGA from "react-ga4";

import Asset1 from "../../asset/new_asset/ilustration/why1.png";
import Asset2 from "../../asset/new_asset/ilustration/why2.png";
import Asset3 from "../../asset/new_asset/ilustration/why3.png";


const Section2 = () => {
    const appContext = useContext(AppContext);
    const { t } = useTranslation();

    const desktopStyles = {
        sectionStyle: {
            backgroundColor: appContext.colors.primaryWhite,
        },

        contentContainerStyle: {
            //height: (window.innerHeight*9/10)+"px",
            zIndex: "10",
            marginTop: "-200px",
            paddingBottom: "100px"
        },

        blueBackgroundContainer: {
            zIndex: "1",
            backgroundColor: "#2360B0",
            height: "350px",
        },

        iconCardStyle: {
            height: "100px",
            width: "auto",
        },

        blueAccent: {
            color: appContext.colors.primaryBlue,
        },

        titleStyle: {
            fontSize: "52px",
            fontWeight: "700",
            fontFamily: appContext.fontFamily.inter,
            color: appContext.colors.primaryWhite,
            marginBottom: "60px"
        },

        headlineStyle: {
            fontSize: "28px",
            fontWeight: "700",
            fontFamily: appContext.fontFamily.inter,
            lineHeight: "120%",
            marginTop: "20px",
            color: appContext.colors.primaryBlack
        },

        subtitleStyle: {
            fontSize: "18px",
            fontWeight: "400",
            lineHeight: "120%",
            color: appContext.colors.secondaryGrey
        },

        customCardStyle: {
            display: "flex",
            padding: "40px 32px",
            flexDirection: "column",
            alignItems: "flex-start",
            flex: " 1 0 0",
            alignSelf: "stretch",
            borderRadius: "20px",
            border: "1px solid #E5E5E5",
            background: "#FBFDFF",
            boxShadow: "0px 4px 40px 0px rgba(23, 64, 118, 0.20)",
        }
    }

    const mobileStyles = {
        sectionStyle: {
            backgroundColor: appContext.colors.primaryWhite,
        },

        contentContainerStyle: {
            //height: (window.innerHeight*9/10)+"px",
            zIndex: "10",
            marginTop: "-350px",
            paddingBottom: "100px"
        },

        blueBackgroundContainer: {
            zIndex: "1",
            backgroundColor: "#2360B0",
            height: "450px",
        },

        iconCardStyle: {
            height: "100px",
            width: "auto",
        },

        blueAccent: {
            color: appContext.colors.primaryBlue,
        },

        titleStyle: {
            fontSize: "28px",
            fontWeight: "700",
            textAlign: "left",
            fontFamily: appContext.fontFamily.poppins,
            color: appContext.colors.primaryWhite,
        },

        headlineStyle: {
            fontSize: "28px",
            fontWeight: "700",
            fontFamily: appContext.fontFamily.inter,
            lineHeight: "120%",
            marginTop: "20px",
            color: appContext.colors.primaryBlack
        },

        subtitleStyle: {
            fontSize: "18px",
            fontWeight: "400",
            lineHeight: "120%",
            color: appContext.colors.secondaryGrey
        },

        customCardStyle: {
            display: "flex",
            padding: "38px 30px",
            flexDirection: "column",
            alignItems: "flex-start",
            flex: " 1 0 0",
            alignSelf: "stretch",
            borderRadius: "20px",
            border: "1px solid #E5E5E5",
            background: "#FBFDFF",
            margin: "15px 10px",
            boxShadow: "0px 4px 40px 0px rgba(23, 64, 118, 0.20)",
        }
    }

    const styles = appContext.mobileMode ? mobileStyles : desktopStyles


    const onDownload = () => {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        ReactGA.initialize("G-3CZYHKHHW6");
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            ReactGA.event({
                category: "download",
                action: "app_store"
            });
            window.open("https://woilo.com/appstore/ios.php?source=202", '_blank').focus();
            return;
        }
        ReactGA.event({
            category: "download",
            action: "play_store"
        });
        window.open("https://woilo.com/appstore/android.php?source=202", '_blank').focus();
    }

    return (
        <>
            <section style={styles.sectionStyle}>
                <Container fluid style={styles.blueBackgroundContainer}>
                </Container>
                <Container style={styles.contentContainerStyle}>
                    <Row className='text-center'>
                        <h2 style={styles.titleStyle}>{t('landingPage.section2.title')}</h2>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <Card style={styles.customCardStyle}>
                                <Image style={styles.iconCardStyle} src={Asset1} />
                                <h2 style={styles.headlineStyle}>{t('landingPage.section2.cardTitle1a')} <span style={styles.blueAccent}>{t('landingPage.section2.cardTitle1b')}</span></h2>
                                <p style={styles.subtitleStyle}>{t('landingPage.section2.cardSub1')}</p>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card style={styles.customCardStyle}>
                                <Image style={styles.iconCardStyle} src={Asset2} />
                                <h2 style={styles.headlineStyle}><span style={styles.blueAccent}>{t('landingPage.section2.cardTitle2a')}</span> {t('landingPage.section2.cardTitle2b')}</h2>
                                <p style={styles.subtitleStyle}> {t('landingPage.section2.cardSub2')}<br /><br /></p>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card style={styles.customCardStyle}>
                                <Image style={styles.iconCardStyle} src={Asset3} />
                                <h2 style={styles.headlineStyle}>{t('landingPage.section2.cardTitle3a')} <span style={styles.blueAccent}>{t('landingPage.section2.cardTitle3b')}</span></h2>
                                <p style={styles.subtitleStyle}>{t('landingPage.section2.cardSub3')}<br /><br /></p>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Section2;
import React, { useContext } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";
import { AppContext, AppContextProvider } from '../../utils/AppContext';
import { useTranslation } from 'react-i18next';
import Feedback from 'react-bootstrap/esm/Feedback';

const FeedbackSection = () => {
    const appContext = useContext(AppContext);
    const { t } = useTranslation();

    const desktopStyles = {
        contentContainerStyle: {
            backgroundColor: appContext.colors.primaryBlue,
            fontFamily: appContext.fontFamily.poppins,
            alingText: "center"
        },

        subtitleStyle: {
            fontSize: "28px",
            fontWeight: "700",
            alignSelf: "center",
            marginBottom: "40px",
            color: appContext.colors.primaryWhite
        },

        downloadButtonStyle: {
            textDecoration: "none",
            fontFamily: appContext.fontFamily.poppins,
            fontSize: "18px",
            fontWeight: "700",
            padding: "10px 20px",
            alignSelf: "center",
            borderRadius: "50px",
            color: appContext.colors.primaryBlue,
            backgroundColor: appContext.colors.primaryWhite,
        },
    }

    const mobileStyles = {
        contentContainerStyle: {
            backgroundColor: appContext.colors.primaryBlue,
            fontFamily: appContext.fontFamily.poppins,
            alingText: "center"
        },

        subtitleStyle: {
            fontSize: "28px",
            fontWeight: "700",
            alignSelf: "center",
            marginBottom: "40px",
            color: appContext.colors.primaryWhite
        },

        downloadButtonStyle: {
            textDecoration: "none",
            fontFamily: appContext.fontFamily.poppins,
            fontSize: "18px",
            fontWeight: "700",
            padding: "20px 30px",
            alignSelf: "center",
            borderRadius: "50px",
            color: appContext.colors.primaryBlue,
            backgroundColor: appContext.colors.primaryWhite,
        },
    }

    const styles = appContext.mobileMode ? mobileStyles : desktopStyles

    const onEmailFeedback = () => {
        window.open("mailto:developer@woilo.com?subject='Hello from Woilo Website", '_blank').focus();
    }

    return (
        <>
            <div style={styles.contentContainerStyle}>
                <Container className='py-5'>
                    <Row className='text-center'>
                        <Col>
                            <h3 style={styles.subtitleStyle}>{t('landingPage.feedback.title')}🔥</h3>
                            <Link style={styles.downloadButtonStyle} onClick={onEmailFeedback}>developer@woilo.com</Link>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default FeedbackSection;
import React from "react";
import { useTranslation } from "react-i18next";

const NftTerms = (props) => {
    const styles = props.styles;
    const { t } = useTranslation();

    return(
        <>  
            <p style={styles.tosContentLastChangedStyle}>{t('termsOfServicePage.lastChanged')}</p>
            <p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.nftTerms.description')}</p>
            <ul >
                <li style={styles.tosListBulletStyle}><p style={styles.tosContentDescriptionStyle}> {t('termsOfServicePage.nftTerms.termList.li1')}</p></li>
                <li style={styles.tosListBulletStyle}><p style={styles.tosContentDescriptionStyle}> {t('termsOfServicePage.nftTerms.termList.li2')}</p></li>
                <li style={styles.tosListBulletStyle}><p style={styles.tosContentDescriptionStyle}> {t('termsOfServicePage.nftTerms.termList.li3')}</p></li>
                <li style={styles.tosListBulletStyle}><p style={styles.tosContentDescriptionStyle}> {t('termsOfServicePage.nftTerms.termList.li4')}</p></li>
                <li style={styles.tosListBulletStyle}><p style={styles.tosContentDescriptionStyle}> {t('termsOfServicePage.nftTerms.termList.li5')}</p></li>
                <li style={styles.tosListBulletStyle}><p style={styles.tosContentDescriptionStyle}> {t('termsOfServicePage.nftTerms.termList.li6')}</p></li>
                <li style={styles.tosListBulletStyle}><p style={styles.tosContentDescriptionStyle}> {t('termsOfServicePage.nftTerms.termList.li7')} <br></br> <a target="_blank" rel="noreferrer" href="https://jdih.kemenkeu.go.id/download/7e87ffb9-d6b1-41a1-8f57-1ad6b868feec/68~PMK.03~2022Per.pdf">{t('termsOfServicePage.nftTerms.taxDocument')}</a></p></li>
                <li style={styles.tosListBulletStyle}><p style={styles.tosContentDescriptionStyle}> {t('termsOfServicePage.nftTerms.termList.li8')}</p></li>
            </ul>
            <p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.nftTerms.description2')}</p>
            <ul >
                <li style={styles.tosListBulletStyle}><p style={styles.tosContentDescriptionStyle}> {t('termsOfServicePage.nftTerms.termList2.li1')}</p></li>
                <li style={styles.tosListBulletStyle}><p style={styles.tosContentDescriptionStyle}> {t('termsOfServicePage.nftTerms.termList2.li2')}</p></li>
                <li style={styles.tosListBulletStyle}><p style={styles.tosContentDescriptionStyle}> {t('termsOfServicePage.nftTerms.termList2.li3')}</p></li>
            </ul>
            <p style={styles.tosContentDescriptionStyle}><b>{t('termsOfServicePage.nftTerms.confirmation')}</b></p>
            <h3 style={styles.tosContentTitleStyle}>{t('termsOfServicePage.changesDisclaimer.title')}</h3>
            <p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.changesDisclaimer.content.p1')}</p>
            <p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.changesDisclaimer.content.p2')}</p>
            <p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.changesDisclaimer.content.p3')}</p>
        </>
    )
}

export default NftTerms
import React, { useContext } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { AppContext } from '../../utils/AppContext';
import { useTranslation } from 'react-i18next';
import ReactGA from "react-ga4";

import Video from "../../asset/new_asset/animation_video/viral.mp4"

const Section4 = () => {
    const appContext = useContext(AppContext);
    const { t } = useTranslation();

    const desktopStyles = {
        containerStyle: {
            backgroundColor: "#EBF3FE",
        },

        headlineStyle: {
            fontSize: "46px",
            fontWeight: "700",
            fontFamily: appContext.fontFamily.inter,
            color: appContext.colors.primaryBlack,
            lineHeight: "120%"
        },

        subtitleStyle: {
            fontSize: "22px",
            fontWeight: "400",
            fontFamily: appContext.fontFamily.inter,
            color: "#959595",
            marginTop: "10px",
            lineHeight: "120%"
        },

        videoStyle: {
            width: "100%",
        },

        learMoreStyle: {
            width: "300px",
            textDecoration: "none",
            textAlign: "center",
            fontSize: "16px",
            fontWeight: "700",
            marginTop: "50px",
            padding: "18px 32px",
            lineHeight: "normal",
            borderRadius: "50px",
            fontFamily: appContext.fontFamily.poppins,
            color: appContext.colors.primaryWhite,
            backgroundColor: appContext.colors.primaryBlue,
        }
    }

    const mobileStyles = {
        containerStyle: {
            backgroundColor: "#EBF3FE",
        },

        headlineStyle: {
            fontSize: "28px",
            fontWeight: "700",
            fontFamily: appContext.fontFamily.inter,
            color: appContext.colors.primaryBlack,
            lineHeight: "120%"
        },

        subtitleStyle: {
            fontSize: "14px",
            fontWeight: "400",
            fontFamily: appContext.fontFamily.inter,
            color: "#959595",
            marginTop: "20px",
            lineHeight: "120%"
        },

        videoStyle: {
            width: "100%",
            marginTop: "60px"
        },

        learMoreStyle: {
            maxWidth: "200px",
            textDecoration: "none",
            textAlign: "center",
            fontSize: "14px",
            fontWeight: "700",
            marginTop: "20px",
            padding: "18px 32px",
            lineHeight: "normal",
            borderRadius: "50px",
            fontFamily: appContext.fontFamily.poppins,
            color: appContext.colors.primaryWhite,
            backgroundColor: appContext.colors.primaryBlue,
        }
    }

    const styles = appContext.mobileMode ? mobileStyles : desktopStyles

    const onDownload = () => {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        ReactGA.initialize("G-3CZYHKHHW6");
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            ReactGA.event({
                category: "download",
                action: "app_store"
            });
            window.open("https://woilo.com/appstore/ios.php?source=202", '_blank').focus();
            return;
        }
        ReactGA.event({
            category: "download",
            action: "play_store"
        });
        window.open("https://woilo.com/appstore/android.php?source=202", '_blank').focus();
    }

    return appContext.mobileMode ? (
        <>
            <section style={styles.containerStyle}>
                <Container className="py-4">
                    <Row className='justify-content-center vertical-center'>
                        <Col className='my-auto' md={6} sm={12}>
                            <h1 style={styles.headlineStyle}>{t('landingPage.section4.title')}🥳✨</h1>
                            <div style={styles.subtitleStyle}>
                                {t('landingPage.section4.subtitle')}
                            </div>
                            <div style={styles.learMoreStyle} onClick={onDownload}>
                                {t('landingPage.section4.download')}
                            </div>
                        </Col>
                        <Col className='justify-content-center' md={6} sm={12}>
                            <video style={styles.videoStyle} autoPlay loop muted playsInline>
                                <source src={Video} type="video/mp4" />
                            </video>
                        </Col>
                    </Row>
                </Container>
            </section>

        </>
    ) : (
        <>
            <section style={styles.containerStyle}>
                <Container className='py-5' >
                    <Row className='justify-content-center vertical-center'>
                        <Col className='my-auto' md={6} sm={12}>
                            <h1 style={styles.headlineStyle}>{t('landingPage.section4.title')}🥳✨</h1>
                            <div style={styles.subtitleStyle}>
                                {t('landingPage.section4.subtitle')}
                            </div>
                            <div style={styles.learMoreStyle} onClick={onDownload}>
                                {t('landingPage.section4.download')}
                            </div>
                        </Col>
                        <Col className='justify-content-center' md={6} sm={12}>
                            <video style={styles.videoStyle} autoPlay loop muted playsInline>
                                <source src={Video} type="video/mp4" />
                            </video>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Section4;
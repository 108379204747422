import React, { useEffect, useState , useContext} from "react";
import { Link} from "react-router-dom";
import { Spinner } from "react-bootstrap";
import $ from 'jquery';
import { useParams } from "react-router-dom";
import ReactGA from "react-ga4";
import UrlHouse from "../../utils/UrlHouse";
import DefaultProfile from '../../asset/default_profile.png';
import VerifiedBadge from '../../asset/verified_badge.png';
import { AppContext } from "../../utils/AppContext";
import Logo from '../../asset/woilo_logo_full.png';

import {Helmet} from "react-helmet";

const PostDeeplink = () => {
    const appContext = useContext(AppContext);

    const [ postData, setPostData ] = useState({
        isLoaded: false,
        postImage: "",
        postCaption: "",
        displayName: "",
        userName: "",
        displayPicture: "",
        isVerified: false,
        postType: 101,
        postLikes: 0,
        timeStamp: ""
    })

    const desktopStyles = {
        mainContainerStyle: {
            width: "50%",
            marginLeft: (window.innerWidth/4)+"px",
            marginRight: (window.innerWidth/4)+"px",
            padding: "30px",
        },

        profilePictureStyle: {
            width: "50px",
            float: "left",
            borderRadius: "50%",
            alignSelf: "center"
        },

        displayNameContainerStyle: {
            float: "left",
            width: (window.innerWidth/2 - 150)+"px",
            marginRight: "0px",
            marginLeft: "10px"
        },

        verifiedBadgeStyle: {
            width: postData.isVerified?"16px":"0px",
            height: postData.isVerified?"16px":"0px",
            marginLeft: postData.isVerified?"5px":"0px",
        },

        displayNameStyle: {
            fontWeight: "500",
            fontSize: "16px",
        },

        userNameStyle: {
            width: (window.innerWidth/2 - 150)+"px",
            fontSize: "13px",
            fontWeight: "400",
            marginLeft: "10px",
            color: appContext.colors.primaryGrey
        },

        postPictureStyle: {
            width: "100%",
            marginTop: "10px",
            borderRadius: "10px"
        },

        likeStyle: {
            width: (window.innerWidth/2 - 60)+"px",
            fontSize: "16px",
            fontWeight: "400",
            color: appContext.colors.primaryBlue,
            marginTop: "10px",
        },

        captionStyle: {
            width: (window.innerWidth/2 - 60)+"px",
            fontSize: "16px",
            fontWeight: "400",
            color: appContext.colors.primaryBlack,
            marginTop: "10px",
            whiteSpace: "pre-wrap"
        },

        headerContainerStyle: {
            height: "50px",
            padding: "10px",
            backgroundColor: appContext.colors.primaryBlue,
        },

        headerLogoStyle: {
            height: "20px",
            marginTop: "5px",
            marginBottom: "5px",
            marginLeft: (window.innerWidth/5)+"px",
            float: "left"
        },

        downloadButtonStyle: {
            marginRight: (window.innerWidth/5)+"px",
            paddingLeft: "5px",
            paddingRight: "5px",
            paddingTop: "3px",
            paddingBottom: "3px",
            fontSize: "15px",
            fontWeight: "500",
            float: "right",
            textDecoration: "none",
            color: appContext.colors.primaryWhite,
            borderStyle: "solid",
            borderRadius: "5px",
            borderColor: appContext.colors.primaryWhite,
            borderWidth: "2px"
        },

        downloadButtonStyleSecondary: {
            width: "100%",
            textAlign: "center",
            marginBottom: "30px",
            padding: "10px",
            fontSize: "15px",
            fontWeight: "500",
            textDecoration: "none",
            color: appContext.colors.primaryWhite,
            borderStyle: "solid",
            borderRadius: "5px",
            backgroundColor: appContext.colors.primaryBlue,
        }
    }

    const mobileStyles = {
        mainContainerStyle: {
            width: "100%",
            padding: "30px",
        },

        profilePictureStyle: {
            width: "50px",
            float: "left",
            borderRadius: "50%",
            alignSelf: "center"
        },

        displayNameContainerStyle: {
            float: "left",
            width: (window.innerWidth - 150)+"px",
            marginRight: "0px",
            marginLeft: "10px"
        },

        verifiedBadgeStyle: {
            width: postData.isVerified?"16px":"0px",
            height: postData.isVerified?"16px":"0px",
            marginLeft: postData.isVerified?"5px":"0px",
        },

        displayNameStyle: {
            fontWeight: "500",
            fontSize: "16px",
        },

        userNameStyle: {
            width: (window.innerWidth - 150)+"px",
            fontSize: "13px",
            fontWeight: "400",
            marginLeft: "10px",
            color: appContext.colors.primaryGrey
        },

        postPictureStyle: {
            width: (window.innerWidth - 60)+"px",
            marginTop: "10px",
            borderRadius: "10px"
        },

        likeStyle: {
            width: (window.innerWidth - 60)+"px",
            fontSize: "16px",
            fontWeight: "400",
            color: appContext.colors.primaryBlue,
            marginTop: "10px",
        },

        captionStyle: {
            width: (window.innerWidth - 60)+"px",
            fontSize: "16px",
            fontWeight: "400",
            color: appContext.colors.primaryBlack,
            marginTop: "10px",
            whiteSpace: "pre-wrap"
        },

        headerContainerStyle: {
            height: "50px",
            padding: "10px",
            backgroundColor: appContext.colors.primaryBlue,
        },

        headerLogoStyle: {
            height: "20px",
            marginTop: "5px",
            marginBottom: "5px",
            float: "left"
        },

        downloadButtonStyle: {
            paddingLeft: "5px",
            paddingRight: "5px",
            paddingTop: "3px",
            paddingBottom: "3px",
            fontSize: "15px",
            fontWeight: "500",
            float: "right",
            textDecoration: "none",
            color: appContext.colors.primaryWhite,
            borderStyle: "solid",
            borderRadius: "5px",
            borderColor: appContext.colors.primaryWhite,
            borderWidth: "2px"
        },

        downloadButtonStyleSecondary: {
            width: (window.innerWidth - 60)+"px",
            textAlign: "center",
            marginBottom: "30px",
            padding: "10px",
            fontSize: "15px",
            fontWeight: "500",
            textDecoration: "none",
            color: appContext.colors.primaryWhite,
            borderStyle: "solid",
            borderRadius: "5px",
            backgroundColor: appContext.colors.primaryBlue,
        }
    }

    const styles = appContext.mobileMode ? mobileStyles : desktopStyles

    useEffect(() => {
      //$('body').css('background-color','#2E80EB');
      initPost();
    }, [])

    const params = useParams();
    const [linkPreview, setLinkPreview] = useState({
        title: "Woilo - Photo, Video, Chat, NFTs",
        description: "Woilo - Photo, Video, Chat, NFTs",
        url: "",
        imageUrl: ""
    })
    
    const initPost = () => {
        $.ajax({
            type: "POST",
            url: UrlHouse.POST_DEEP_LINK,
            data: {post_id : params.post_id},
            dataType: "json",
            success: function (response) {
                if(response.result === 1){
                    let postDataResult = {
                        isLoaded: true,
                        postImage: response.post_picture,
                        postCaption: response.post_caption,
                        displayName: response.display_name,
                        userName: response.user_name,
                        displayPicture: response.display_picture,
                        isVerified: response.is_verified === 1,
                        postType: response.post_type,
                        postLikes: response.post_likes,
                        timeStamp: response.time_stamp
                    };

                    setPostData(postDataResult);

                    setLinkPreview({
                        title: response.display_name+" (@"+response.user_name+") • Woilo",
                        description: response.post_likes+" suka | "+response.time_stamp+" | "+response.post_caption,
                        imageUrl: response.post_picture,
                        url: "woilo.com"
                    });
                }
            },
            error: function(e) {
                console.warn(e);
            }
        });
    }

    const onDownload = () => {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        ReactGA.initialize("G-3CZYHKHHW6");
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            ReactGA.event({
                category: "download",
                action: "app_store"
            });
            window.open("https://woilo.com/appstore/ios.php?source=202", '_blank').focus();
            return;
        }
        ReactGA.event({
            category: "download",
            action: "play_store"
        });
        window.open("https://woilo.com/appstore/android.php?source=202", '_blank').focus();
    }

    return(
        <>
            <div style={styles.outerContainerStyle}>
                <Helmet>
                    <title>{linkPreview.title}</title>

                    <meta property="og:title" content={linkPreview.title} />
                    <meta property="og:description" content={linkPreview.description} />
                    <meta property="og:image" content={linkPreview.imageUrl} />
                    <meta property="og:url" content={linkPreview.url} />
                    <meta property="og:type" content="website"/>

                    <meta name="twitter:card" content="summary_large_image" />
                    <meta property="twitter:domain" content="woilo.com" />
                    <meta property="twitter:url" content={linkPreview.url} />
                    <meta name="twitter:title" content={linkPreview.title} />
                    <meta name="twitter:description" content={linkPreview.description} />
                    <meta name="twitter:image" content={linkPreview.imageUrl} />
                </Helmet>
                {
                    postData.isLoaded ?
                    <>
                        <div style={styles.headerContainerStyle}>
                            <Link
                                onClick={() => {
                                    window.open("https://woilo.com","_self");
                                }}>
                                <img alt="woilo" src={Logo}  style={styles.headerLogoStyle} className="d-inline-block"/>
                            </Link>
                            <Link style={styles.downloadButtonStyle} onClick={onDownload}>Unduh Woilo</Link>
                        </div>
                        <div style={styles.mainContainerStyle}>
                            <div>
                                <img style={styles.profilePictureStyle} src={postData.displayPicture.length > 0?postData.displayPicture:DefaultProfile} alt="profilePicture"/>
                                <div>
                                    <div style={styles.displayNameContainerStyle}>
                                        <label style={styles.displayNameStyle}>{postData.displayName}</label>
                                        <img style={styles.verifiedBadgeStyle} src={VerifiedBadge} alt="verifiedBadge"/>
                                    </div>
                                    <label style={styles.userNameStyle}>{postData.userName}  •  {postData.timeStamp}</label>
                                </div>
                            </div>
                            <img style={styles.postPictureStyle} src={postData.postImage} alt="postPicture"/>
                            <p style={styles.likeStyle}>{postData.postLikes} suka</p>
                            <p style={styles.captionStyle}>{postData.postCaption}</p>
                            <div style={styles.downloadButtonStyleSecondary} onClick={onDownload}>Unduh Aplikasi Woilo</div>
                        </div>
                    </>
                    :
                    <div>
                        <div style={styles.headerContainerStyle}>
                            <img alt="woilo" src={Logo}  style={styles.headerLogoStyle} className="d-inline-block"/>
                            <Link style={styles.downloadButtonStyle} onClick={onDownload}>Unduh Woilo</Link>
                        </div>
                        <div style={{
                            width: "50%",
                            height: "100vh",
                        }}>
                        </div>
                    </div>
                    
                }
            </div>
        </>
    )
}

export default PostDeeplink;
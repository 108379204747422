import React from "react";
import { useTranslation } from "react-i18next";

const FootballTerms = (props) => {
    const styles = props.styles;
    const { t } = useTranslation();

    return(
        <>
            <p style={styles.tosContentLastChangedStyle}>{t('termsOfServicePage.lastChanged')}</p>
            <p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.footballTerms.description')}</p>
            <h4 style={styles.tosContentSubtitleStyle}>{t('termsOfServicePage.footballTerms.subtitle')}</h4>
            <p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.footballTerms.subtitleDescription')}</p>
            <ul >
                <li style={styles.tosListBulletStyle}><p style={styles.tosContentDescriptionStyle}> {t('termsOfServicePage.footballTerms.termList1.li1')}</p></li>
                <li style={styles.tosListBulletStyle}><p style={styles.tosContentDescriptionStyle}> {t('termsOfServicePage.footballTerms.termList1.li2')}</p></li>
            </ul>
            <p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.footballTerms.warning')}</p>
            <h4 style={styles.tosContentSubtitleStyle}>{t('termsOfServicePage.footballTerms.subtitle2')}</h4>
            <ul >
                <li style={styles.tosListBulletStyle}><p style={styles.tosContentDescriptionStyle}> {t('termsOfServicePage.footballTerms.termList2.li1')}</p></li>
                <li style={styles.tosListBulletStyle}><p style={styles.tosContentDescriptionStyle}> {t('termsOfServicePage.footballTerms.termList2.li2')}</p></li>
            </ul>
            <h3 style={styles.tosContentTitleStyle}>{t('termsOfServicePage.changesDisclaimer.title')}</h3>
            <p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.changesDisclaimer.content.p1')}</p>
            <p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.changesDisclaimer.content.p2')}</p>
            <p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.changesDisclaimer.content.p3')}</p>
        </>
    )
}

export default FootballTerms
import React from "react";
import NavigationBar from "../sections/NavigationBar";
import { Outlet } from "react-router-dom";
import FooterSection from "../sections/FooterSection";
import {Helmet} from "react-helmet";

const LandingPage = () => {

    return(
        <>  
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Woilo • Photo, Video, Chat, NFT, News</title>

                    <meta property="og:title" content="Woilo • Photo, Video, Chat, NFT, News" />
					<meta property="og:description" content="Woilo • Photo, Video, Chat, NFT, News" />
					<meta property="og:type" content="website"/>
					
					<meta name="twitter:card" content="summary_large_image" />
					<meta property="twitter:domain" content="woilo.com" />
					<meta name="twitter:title" content="Woilo • Photo, Video, Chat, NFT, News" />
					<meta name="twitter:description" content="Woilo • Photo, Video, Chat, NFT, News" />
                </Helmet>
                <NavigationBar/>
                <Outlet/>
                <FooterSection/>
            </div>
        </>
    )
}

export default LandingPage;
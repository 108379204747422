import React, { useEffect, useState , useContext} from "react";
import { Card, Row, Col, Spinner } from "react-bootstrap";
import $ from 'jquery';
import { useParams, Link } from "react-router-dom";
import ReactGA from "react-ga4";
import UrlHouse from "../../utils/UrlHouse";
import DefaultProfile from '../../asset/default_profile.png';
import VerifiedBadge from '../../asset/verified_badge.png';
import { AppContext } from "../../utils/AppContext";
import Logo from '../../asset/woilo_logo_full.png';
import {Helmet} from "react-helmet";

const UserDeepLink = () => {

    const appContext = useContext(AppContext);

    const [ userData, setUserData ] = useState({
        isLoaded: false,
        displayPicture: "",
        displayName: "",
        userName: "",
        displayStatus: "",
        isVerified: false,
        postCount: "",
        followerCount: "",
        followingCount: "",
        posts: []
    })
    
    const desktopStyles = {
        mainContainerStyle: {
            width: "50%",
            marginLeft: (window.innerWidth/4)+"px",
            marginRight: (window.innerWidth/4)+"px",
            padding: "30px",
            textAlign: "center"
        },

        profilePictureStyle: {
            width: "30%",
            borderRadius: "50%",
            alignSelf: "center"
        },

        displayNameContainerStyle: {
            width: (window.innerWidth/2 - 60)+"px",
        },

        verifiedBadgeStyle: {
            width: userData.isVerified?"20px":"0px",
            height: userData.isVerified?"20px":"0px",
            marginBottom: userData.isVerified?"5px":"0px",
            marginLeft: userData.isVerified?"5px":"0px",
        },

        displayNameStyle: {
            fontWeight: "500",
            fontSize: "20px",
            marginTop: "10px"
        },

        userNameStyle: {
            width: (window.innerWidth/2 - 60)+"px",
            fontSize: "16px",
            fontWeight: "400"
        },

        bioStyle: {
            width: (window.innerWidth/2 - 60)+"px",
            fontSize: "16px",
            fontWeight: "400",
            marginTop: "10px",
            whiteSpace: "pre-wrap"
        },

        postCountContainer: {
            width: (window.innerWidth/2 - 120)+"px",
            marginLeft: "15px",
            marginRight: "15px",
            marginTop: "10px"
        },

        postCountTitleStyle: {
            fontWeight: "400",
            fontSize: "15px",
            margin: "0px",
            color: appContext.colors.primaryGrey
        },

        postCountStyle: {
            fontSize: "20px",
            fontWeight: "500",
            margin: "0px",
            color: appContext.colors.primaryBlack
        },

        primaryButtonContainerStyle: {
            textDecoration: "none",
            width: (window.innerWidth/2 - 60)+"px",
            padding: "10px",
            marginTop: "10px",
            backgroundColor: appContext.colors.primaryBlue,
            borderRadius: "5px"
        },

        primaryButtonStyle: {
            fontWeight: "500",
            color: appContext.colors.primaryWhite,
        },

        headerContainerStyle: {
            height: "50px",
            padding: "10px",
            backgroundColor: appContext.colors.primaryBlue,
        },

        headerLogoStyle: {
            height: "20px",
            marginTop: "5px",
            marginBottom: "5px",
            marginLeft: (window.innerWidth/5)+"px",
            float: "left"
        },

        downloadButtonStyle: {
            marginRight: (window.innerWidth/5)+"px",
            paddingLeft: "5px",
            paddingRight: "5px",
            paddingTop: "3px",
            paddingBottom: "3px",
            fontSize: "15px",
            fontWeight: "500",
            float: "right",
            textDecoration: "none",
            color: appContext.colors.primaryWhite,
            borderStyle: "solid",
            borderRadius: "5px",
            borderColor: appContext.colors.primaryWhite,
            borderWidth: "2px"
        }
    }

    const mobileStyles = {
        mainContainerStyle: {
            width: "100%",
            padding: "30px",
            textAlign: "center"
        },

        profilePictureStyle: {
            width: "40%",
            borderRadius: "50%",
            alignSelf: "center"
        },

        displayNameContainerStyle: {
            width: (window.innerWidth - 60)+"px",
        },

        verifiedBadgeStyle: {
            width: userData.isVerified?"20px":"0px",
            height: userData.isVerified?"20px":"0px",
            marginBottom: userData.isVerified?"5px":"0px",
            marginLeft: userData.isVerified?"5px":"0px",
        },

        displayNameStyle: {
            fontWeight: "500",
            fontSize: "20px",
            marginTop: "10px"
        },

        userNameStyle: {
            width: (window.innerWidth - 60)+"px",
            fontSize: "16px",
            fontWeight: "400"
        },

        bioStyle: {
            width: (window.innerWidth - 60)+"px",
            fontSize: "16px",
            fontWeight: "400",
            marginTop: "10px",
            whiteSpace: "pre-wrap"
        },

        postCountContainer: {
            width: (window.innerWidth - 120)+"px",
            marginLeft: "15px",
            marginRight: "15px",
            marginTop: "10px"
        },

        postCountTitleStyle: {
            fontWeight: "400",
            fontSize: "15px",
            margin: "0px",
            color: appContext.colors.primaryGrey
        },

        postCountStyle: {
            fontSize: "20px",
            fontWeight: "500",
            margin: "0px",
            color: appContext.colors.primaryBlack
        },

        primaryButtonContainerStyle: {
            textDecoration: "none",
            width: (window.innerWidth - 60)+"px",
            padding: "10px",
            marginTop: "10px",
            backgroundColor: appContext.colors.primaryBlue,
            borderRadius: "5px"
        },

        primaryButtonStyle: {
            fontWeight: "500",
            color: appContext.colors.primaryWhite,
        },

        headerContainerStyle: {
            height: "50px",
            padding: "10px",
            backgroundColor: appContext.colors.primaryBlue,
        },

        headerLogoStyle: {
            height: "20px",
            marginTop: "5px",
            marginBottom: "5px",
            float: "left"
        },

        downloadButtonStyle: {
            paddingLeft: "5px",
            paddingRight: "5px",
            paddingTop: "3px",
            paddingBottom: "3px",
            fontSize: "15px",
            fontWeight: "500",
            float: "right",
            textDecoration: "none",
            color: appContext.colors.primaryWhite,
            borderStyle: "solid",
            borderRadius: "5px",
            borderColor: appContext.colors.primaryWhite,
            borderWidth: "2px"
        }
    }

    const styles = appContext.mobileMode ? mobileStyles : desktopStyles

    useEffect(() => {
        //$('body').css('background-color','#2E80EB');
        initUser();
    }, [])

    const params = useParams();

    const onDownload = () => {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        ReactGA.initialize("G-3CZYHKHHW6");
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            ReactGA.event({
                category: "download",
                action: "app_store"
            });
            window.open("https://woilo.com/appstore/ios.php?source=202", '_blank').focus();
            return;
        }
        ReactGA.event({
            category: "download",
            action: "play_store"
        });
        window.open("https://woilo.com/appstore/android.php?source=202", '_blank').focus();
    }

    const [linkPreview, setLinkPreview] = useState({
        title: "Woilo - Photo, Video, Chat, NFTs",
        description: "Woilo - Photo, Video, Chat, NFTs",
        url: "",
        imageUrl: ""
    })

    const initUser = () => {
        $.ajax({
            type: "POST",
            url: UrlHouse.USER_DEEP_LINK,
            data: {user_name : params.user_name},
            dataType: "json",
            success: function (response) {
                if(response.result === 1){
                    let userDataResult = {
                        isLoaded: true,
                        displayPicture: response.display_picture,
                        displayName: response.display_name,
                        userName: response.user_name,
                        displayStatus: response.display_status,
                        isVerified: response.is_verified === 1,
                        postCount: response.post_count,
                        followerCount: response.follower_count,
                        followingCount: response.following_count,
                        posts: response.posts
                    }
                    setUserData(userDataResult);
                    setLinkPreview({
                        title: response.display_name+" (@"+response.user_name+") • Woilo",
                        description: "Ikuti @"+response.user_name+" di Woilo sekarang! | "+response.display_status+" | "+response.follower_count+" pengikut • "+response.following_count+" mengikuti • "+response.post_count+" postingan",
                        imageUrl: response.display_picture,
                        url: "woilo.com"
                    });
                }else{
                }
            },
            error: function(e) {
                console.warn(e);
            }
        });
    }
    

    return(
        <>
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{linkPreview.title}</title>

                    <meta property="og:title" content={linkPreview.title} />
					<meta property="og:description" content={linkPreview.description} />
					<meta property="og:image" content={linkPreview.imageUrl} />
					<meta property="og:url" content={linkPreview.url} />
					<meta property="og:type" content="website"/>
					
					<meta name="twitter:card" content="summary_large_image" />
					<meta property="twitter:domain" content="woilo.com" />
					<meta property="twitter:url" content={linkPreview.url} />
					<meta name="twitter:title" content={linkPreview.title} />
					<meta name="twitter:description" content={linkPreview.description} />
					<meta name="twitter:image" content={linkPreview.imageUrl} />
                </Helmet>
                {
                    userData.isLoaded ?
                    <>
                        <div style={styles.headerContainerStyle}>
                            <Link
                                onClick={() => {
                                    window.open("https://woilo.com","_self");
                                }}>
                                <img alt="woilo" src={Logo}  style={styles.headerLogoStyle} className="d-inline-block"/>
                            </Link>
                            <Link style={styles.downloadButtonStyle} onClick={onDownload}>Unduh Woilo</Link>
                        </div>
                        <div style={styles.mainContainerStyle}>
                            <img style={styles.profilePictureStyle} src={userData.displayPicture.length > 0?userData.displayPicture:DefaultProfile} alt="profilePicture"/>
                            <div style={styles.displayNameContainerStyle}>
                                <label style={styles.displayNameStyle}>{userData.displayName}</label>
                                <img style={styles.verifiedBadgeStyle} src={VerifiedBadge} alt="verifiedBadge"/>
                            </div>
                            <label style={styles.userNameStyle}>@{userData.userName}</label>
                            <div style={styles.postCountContainer}>
                                <Row style={styles.postCountContainer}>
                                    <Col>
                                        <h3 style={styles.postCountStyle}>
                                            {userData.postCount}
                                        </h3>
                                        <p style={styles.postCountTitleStyle}>
                                            Post
                                        </p>
                                    </Col>
                                    <Col>
                                    <h3 style={styles.postCountStyle}>
                                            {userData.followerCount}
                                        </h3>
                                        <p style={styles.postCountTitleStyle}>
                                            Follower
                                        </p>
                                    </Col>
                                    <Col>
                                    <h3 style={styles.postCountStyle}>
                                            {userData.followingCount}
                                        </h3>
                                        <p style={styles.postCountTitleStyle}>
                                            Following
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                            <div style={styles.primaryButtonContainerStyle}  onClick={onDownload}>
                                <div style={styles.primaryButtonStyle}>Follow on Woilo</div>
                            </div>
                            <p style={styles.bioStyle}>{userData.displayStatus}</p>
                        </div>
                    </>
                    :
                    <div>
                        <div style={styles.headerContainerStyle}>
                            <img alt="woilo" src={Logo}  style={styles.headerLogoStyle} className="d-inline-block"/>
                            <Link style={styles.downloadButtonStyle} onClick={onDownload}>Unduh Woilo</Link>
                        </div>
                        <div style={{
                            width: "50%",
                            height: "100vh",
                        }}>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

export default UserDeepLink;